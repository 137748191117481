<template>
  <div id="SalesOrders">
    <v-card>
      <v-card-text>
        <v-data-table @click:row="viewSalesOrder" :headers="headers" :items="orders" class="elevation-0">
          <template v-slot:no-data>No Orders Available</template>
          <template v-slot:no-results>No Orders Available</template>
          <template v-slot:item.statusId="{item}">
            <v-tooltip bottom>
              <template v-slot:activator="{on}">
                <v-btn icon v-on="on" :style="`color:${IDS.TripOrderStatusColor[item.status]}`">
                  <v-icon>mdi-checkbox-blank</v-icon>
                </v-btn>
              </template> <span>{{item.TripOrderStatus&&item.TripOrderStatus.name}}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.created_at="{ item }">{{ moment(item.created_at).format('DD MMM YYYY hh:mm A') }}</template>
          <template v-slot:item.orderNum="{ item }">#{{item.orderNum}}</template>
          <template v-slot:item.shopName="{ item }">{{item.Organization.name}}</template>
          <template v-slot:item.customerName="{ item }">{{ (item.Organization.Users.length&&item.Organization.Users[0].name)||'-' }}</template>
          <template v-slot:item.route="{ item }">{{item.ToRouteShops.length?item.ToRouteShops[0].Route.name:'-'}}</template>
          <template v-slot:item.roundOfValue="{ item }">{{ currencyFormatter(item.finalPrice) }}</template>
          <template v-slot:item.orderItemCount="{ item }">{{ item.OrderItems_aggregate.aggregate.count }}</template>
          <template v-slot:item.updated_at="{ item }">{{(moment(item.updated_at).format('DD MMM YYYY hh:mm A'))}}</template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <SalesOrderDetail :dialog.sync="orderDetailDialog" :orderId="orderId"/>
  </div>
</template>

<script>
import {SUBSCRIBE_DISTRIBUTOR_SALES_ORDERS} from "../../graphql/subscriptions/SalesOrders";
import moment from 'moment';
import {IDS} from "../../Constants/IDS";
import SalesOrderDetail from "../Orders/SalesOrderDetail";
export default {
  name: 'SalesOrders',
  components: {SalesOrderDetail},
  props: ['orgId'],
  apollo:{
    $subscribe:{
      Orders: {
        query: SUBSCRIBE_DISTRIBUTOR_SALES_ORDERS,
        variables() { return { orgId: this.orgId } },
        result ({data}) { this.orders = data.Orders; this.loading = false }
      },
    }
  },
  data () {
    return {
      moment, IDS,
      orders: [],
      headers: [
        { text: 'Created On', value: 'created_at', width:180},
        {text: 'Status', value: 'statusId'}, { text: 'Order ID', value: 'orderNum'},
        { text: 'Shop Name', value: 'shopName', width:200}, { text: 'Customer Name', value: 'customerName', width:200},
        { text: 'Route', value: 'route', width:150}, { text: 'Amount', value: 'roundOfValue' },
        { text: 'Ordered Item', value: 'orderItemCount'}, { text: 'Updated On', value: 'updated_at', width:180}
      ],
      orderId: null,
      orderDetailDialog: false,
    }
  },
  methods: {
    viewSalesOrder(row){
      this.orderId = row.id;
      this.orderDetailDialog = true;
    },
  },
}
</script>

<style scoped>
/deep/ tr:hover {
  cursor: pointer !important;
}
</style>

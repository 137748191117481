var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"SalesOrders"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.orders},on:{"click:row":_vm.viewSalesOrder},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v("No Orders Available")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v("No Orders Available")]},proxy:true},{key:"item.statusId",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({style:(("color:" + (_vm.IDS.TripOrderStatusColor[item.status]))),attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-checkbox-blank")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(item.TripOrderStatus&&item.TripOrderStatus.name))])])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.moment(item.created_at).format('DD MMM YYYY hh:mm A')))]}},{key:"item.orderNum",fn:function(ref){
var item = ref.item;
return [_vm._v("#"+_vm._s(item.orderNum))]}},{key:"item.shopName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.Organization.name))]}},{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s((item.Organization.Users.length&&item.Organization.Users[0].name)||'-'))]}},{key:"item.route",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.ToRouteShops.length?item.ToRouteShops[0].Route.name:'-'))]}},{key:"item.roundOfValue",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.currencyFormatter(item.finalPrice)))]}},{key:"item.orderItemCount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.OrderItems_aggregate.aggregate.count))]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s((_vm.moment(item.updated_at).format('DD MMM YYYY hh:mm A'))))]}}])})],1)],1),_c('SalesOrderDetail',{attrs:{"dialog":_vm.orderDetailDialog,"orderId":_vm.orderId},on:{"update:dialog":function($event){_vm.orderDetailDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
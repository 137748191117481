import gql from 'graphql-tag'

export const SUBSCRIBE_ROUTES_COUNT = gql`
    subscription {
        Routes:Routes_aggregate{
            aggregate{
                count
            }
        }
    }`
export const SUBSCRIBE_ASSIGNED_ROUTE_COUNT = gql`
    subscription {
        AssignedRoutes:Routes_aggregate(where: {organizationId: {_is_null: false}}){
            aggregate{
                count
            }
        }
    }`
export const SUBSCRIBE_UNASSIGNED_ROUTE_COUNT = gql`
    subscription {
        UnAssignedRoutes:Routes_aggregate(where: {organizationId: {_is_null: true}}){
            aggregate{
                count
            }
        }
    }`
export const SUBSCRIBE_ROUTES = gql`
    subscription($condition:Routes_bool_exp){
        Routes:Routes(where:$condition, order_by:{created_at:desc}){
            id
            name
            routeCode
            organizationId
            status
            rtoCodeId
            city
            state
            created_at
            updated_at
            Organization{
                id
                name
            }
            RouteShops_aggregate {
                aggregate {
                    count
                }
            }
        }
    }`
export const SUBSCRIBE_ORGANIZATION_UNASSIGNED_ROUTES = gql`
    subscription {
        Routes:Routes(where: {organizationId: {_is_null: true}}){
            id
            name
            routeCode
            organizationId
            created_at
            RouteShops(order_by:{sequence:asc}) {
                id
            }
        }
    }`
export const SUBSCRIBE_ORGANIZATION_ASSIGNED_ROUTES = gql`
    subscription ($orgId:uuid!){
        Routes:Routes(where: {organizationId: {_eq:$orgId}}){
            id
            name
            routeCode
            organizationId
            created_at
            RouteShops(order_by:{sequence:asc}) {
                id
            }
        }
    }`

export const GET_ROUTE_BY_ID = gql`
    subscription ($id:uuid!){
        Routes: Routes(where: {id: {_eq: $id}}){
            id
            name
            routeCode
            organizationId
            created_at
            updated_at
            Organization{
                id
                name
            }
            RouteShops(order_by:{sequence:asc}){
                id
                sequence
                status
                Organization{
                    id
                    name
                    mobile
                }
            }
        }
    }`

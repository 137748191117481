<template>
  <div id="DistributorTeam">
    <v-card>
      <v-card-text>
        <v-data-table hide-default-footer class="elevation-0" :headers="headers" :items="distributor.Users">
          <template v-slot:no-data>No Members Available</template>
          <template v-slot:no-results>No Members Available</template>
          <template v-slot:item.email="{ item }">
            {{ checkVal(item.email) }}
          </template>
          <template v-slot:item.birthDate="{ item }">
            {{ dateFormatter(item.birthDate) }}
          </template>
          <template v-slot:item.anniversary="{ item }">
            {{ dateFormatter(item.anniversary) }}
          </template>
          <template v-slot:item.roleType="{ item }">
            <div class="d-flex">
              <v-chip small v-if="item.isKeyOwner && item.Type.name==='Owner'" color="primary">
                Default Owner
              </v-chip>
              <v-chip v-else small class="text--primary" color="secondary">{{ item.Type.name }}</v-chip>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'DistributorTeam',
  props: { distributor: { type: [Object], default: null } },
  data () {
    return {
      headers: [
        { text: 'Name', value: 'name' }, { text: 'Mobile', value: 'mobile' }, { text: 'Email', value: 'email' },
        { text: 'BirthDate', value: 'birthDate' }, { text: 'Anniversary', value: 'anniversary' },
        { text: 'Role/Type', value: 'roleType' }],
    }
  },
}
</script>

<style scoped>

</style>

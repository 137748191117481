import gql from 'graphql-tag'

export const CREATE_UPDATE_USER = gql`
    mutation ($users:[Users_insert_input!]!, $user_on_conflict_rule: Users_on_conflict!) {
        insert_Users(objects: $users, on_conflict: $user_on_conflict_rule){
            returning{
                id
            }
        }
    }`

export const SOFT_DELETE_USER = gql`
    mutation ($id: uuid!,$status:String!){
        update_Users(where: {id: {_eq: $id}}, _set: {status: $status}){
            returning{
                id
            }
        }
    }`

export const DELETE_USER = gql`
    mutation ($id: uuid!){
        delete_Users(where: {id: {_eq: $id}}){
            returning{
                id
            }
        }
    }`

<template>
  <div id="PurchaseOrders">
    <v-card>
      <v-card-text>
        <v-data-table @click:row="viewOrder" :headers="headers" :items="distributor.PurchaseOrders"
                      class="elevation-0">
          <template v-slot:no-data>No Orders Available</template>
          <template v-slot:no-results>No Orders Available</template>
          <template v-slot:item.orderNum="{ item }">
            {{ checkVal(item.orderNum) }}
          </template>
          <template v-slot:item.roundOfValue="{ item }">
            {{ currencyFormatter(item.roundOfValue) }}
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ dateTimeFilter(item.created_at) }}
          </template>
          <template v-slot:item.tallyInvoiceNum="{ item }">
            {{ checkVal(item.tallyInvoiceNum) }}
          </template>
          <template v-slot:item.invoiceDate="{ item }">
            {{ dateFormatter(item.invoiceDate) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <OrderDetail :orderId="order.id" :orderDetailDialog="orderDetailDialog" @cancel="orderDetailDialog=false"
                 v-if="orderDetailDialog"></OrderDetail>
  </div>
</template>

<script>
import OrderDetail from '@/components/Orders/OrderDetail'

export default {
  name: 'PurchaseOrders',
  components: { OrderDetail },
  props: { distributor: { type: [Object], default: null } },
  data () {
    return {
      order: null, orderDetailDialog: false,
      headers: [
        { text: 'Order ID', value: 'orderNum' }, { text: 'Amount', value: 'roundOfValue' },
        { text: 'Order Date', value: 'created_at' }, { text: 'Invoice Number', value: 'tallyInvoiceNum' },
        { text: 'Invoice Date', value: 'invoiceDate' }],
    }
  },
  methods: {
    viewOrder (row) {
      this.order = row
      this.orderDetailDialog = true
    },
  },
}
</script>

<style scoped>
/deep/ tr:hover {
  cursor: pointer !important;
}
</style>

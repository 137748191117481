<template>
  <div id="ManageRoutes">
    <v-card>
      <v-card-text v-if="assignedRoutes.length">
        <v-row>
          <v-col cols="8">
            <v-text-field :loading="loading" clearable dense flat hide-details label="Search Routes"
                          outlined style="background: white" v-model="searchRoute"></v-text-field>
          </v-col>
          <v-col cols="4" class="text-right">
            <v-btn depressed @click="startAssignRoutes" text class="primary">ASSIGN ROUTES</v-btn>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn depressed @click="startCreateRoute" text class="primary">ADD NEW ROUTE</v-btn>
            <v-btn depressed @click="exportRouteDialog=true" text class="primary ml-2">EXPORT</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-list>
              <v-list-item @click="viewRouteDetail(route)" v-for="(route,index) in filteredRoutes" :key="index">
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">{{ route.name }} ({{route.routeCode}})</v-list-item-title>
                  <v-list-item-subtitle class="text-caption">Customer ({{ route.RouteShops.length }})</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click.stop="confirmUnAssignRoute(route)" color="red" small icon>
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-row align="center" justify="center">
          <v-col cols="12" class="text-center headline">No Routes Assigned</v-col>
          <v-col cols="12" class="text-center">
            <v-btn depressed @click="startAssignRoutes" text class="primary">ASSIGN ROUTES</v-btn>
            <v-btn depressed @click="startCreateRoute" text class="primary ml-2">ADD NEW ROUTE</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <AssignRoutes @showToast="$emit('showToast',$event)" :distributor="distributor" @cancel="assignRouteDialog=false"
                  :assignRouteDialog="assignRouteDialog" @assignRoute="assignRouteDialog=false"
                  v-if="assignRouteDialog"></AssignRoutes>
    <CreateUpdateRoute @cancel="saveRouteDialog=false" @saveRoute="saveRoute" v-if="saveRouteDialog"
                       :saveRouteDialog="saveRouteDialog"/>
    <RouteShopList :route="selectedRoutes" :routeShopDialog="routeShopDialog" v-if="routeShopDialog"
                   @cancel="routeShopDialog=false"></RouteShopList>
    <ConfirmationDialog :confirmationMessage="confirmMsg" @close="confirmationDialog=false;confirmMsg = 'Confirm! '"
                        @confirm="confirmAction" v-if="confirmationDialog"></ConfirmationDialog>
    <ExportRoutes :dialog.sync="exportRouteDialog" :distributor="distributor"/>
  </div>
</template>

<script>
import { SUBSCRIBE_ORGANIZATION_ASSIGNED_ROUTES } from '../../graphql/subscriptions/Routes'
import AssignRoutes from '../../components/Distributors/AssignRoutes'
import { UNASSIGN_ORGANIZATION_ROUTES } from '@/graphql/mutations/Routes'
import ConfirmationDialog from '@/components/Common/ConfirmationDialog'
import RouteShopList from '../../components/Routes/RouteShopList'
import CreateUpdateRoute from "../Routes/CreateUpdateRoute";
import ExportRoutes from "./ExportRoutes";

export default {
  name: 'ManageRoutes',
  components: {ExportRoutes, CreateUpdateRoute, RouteShopList, ConfirmationDialog, AssignRoutes },
  props: {
    distributor: { type: [Object], default: null },
    manageRouteDialog: { type: [Boolean], default: false },
  },
  data () {
    return {
      assignedRoutes: [], selectedRoutes: [], searchRoute: '', filteredRoutes: [],
      loading: true, assignRouteDialog: false, saveRouteDialog: false, routeShopDialog: false,
      confirmMsg: '', confirmationDialog: false, exportRouteDialog: false
    }
  },
  apollo: {
    $subscribe: {
      Routes: {
        query: SUBSCRIBE_ORGANIZATION_ASSIGNED_ROUTES, fetchPolicy: 'network-only',
        variables () {return { orgId: this.distributor.id }},
        result ({ data, loading }) {
          if (!loading) {
            this.assignedRoutes = JSON.parse(JSON.stringify(data.Routes))
            this.filteredRoutes = JSON.parse(JSON.stringify(data.Routes))
            this.loading = false
          }
        },
      },
    },
  },
  watch: {
    searchRoute (val) {
      if (val)
        this.filteredRoutes = this.assignedRoutes.filter(route => {
          return (route.name.toLowerCase().indexOf(val.toLowerCase()) !== -1)
        })
      else
        this.filteredRoutes = this.assignedRoutes
    },
  },
  methods: {
    startAssignRoutes () {this.assignRouteDialog = true},
    startCreateRoute () {this.saveRouteDialog = true},
    viewRouteDetail (route) {
      this.selectedRoutes = route.id
      this.routeShopDialog = true
    },
    saveRoute () {
      this.$emit('showToast', { enable: true, color: 'green', message: 'Route saved successfully' });
      this.saveRouteDialog = false

    },
    confirmAction () {
      this.confirmationDialog = false
      this.unassignRoute()
    },
    async confirmUnAssignRoute (route) {
      this.selectedRoutes = route.id
      this.confirmMsg = 'Are you sure, you want to unassign this route?'
      this.confirmationDialog = true
    },
    async unassignRoute () {
      let res = await this.$apollo.mutate({
        mutation: UNASSIGN_ORGANIZATION_ROUTES, variables: { routeIds: [this.selectedRoutes] },
      })
      if (res.data) {
        this.$emit('showToast', { enable: true, color: 'green', message: 'Routes unassigned successfully' })
      }
    },
  },
}
</script>

<template>
<div>

    <v-container fluid>
    <v-card outlined width="100%" class="product-card">
    <v-row v-for="(product,index) in products" :key="index">
        <v-col cols="12" class="py-0">
            <v-card elevation="0" class="py-0">
                <v-card-text class="py-0">
                    <v-row row wrap>
                        <v-col cols="2">
                            <div class="raj-wafer-images text-center img-div" v-if="product.image" @click="openGallery([{image: product.image}])">
                                <img width="50" class="d-block mx-auto" alt="imges not found" :src="imgWpx(product.image, 200)">
                                <v-chip label text-color="white" class="mt-3" x-small :style="`background-color: ${packageColor[product.packageType]}`"
                                        v-if="product.packageType">{{ product.packageType.toUpperCase() }}
                                </v-chip>
                            </div>
                            <div style="position: relative;top: calc(50% - 13px);text-align: -webkit-center;" v-else><v-icon>image</v-icon></div>
                        </v-col>
                        <v-col cols="10">
                            <v-row>
                                <v-col class="pa-0 px-1" cols="6">
                                    <div>
                                        <h6 class="text-subtitle-1 font-weight-bold" :style="{'color': product.colorCode?product.colorCode: '#3172d0'}">
                                            {{product.displayName }}</h6>
                                        <p class="text-left mb-1 font-weight-medium " v-if="product.boxes.stock || product.packets.stock">
                                            {{ product.boxes.stock }} {{product.containerType}}es , {{ product.packets.stock }} Packets
                                        </p>
                                        <p v-else class="error--text">Stock Unavailable</p>
                                        <p class="text-caption font-italic mb-0">{{ product.noOfPieceInBox }} Pieces in {{product.containerType}}</p>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="py-0" cols="12"><v-divider/></v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    </v-card>
    </v-container>

    <gallery id="gallery-org-stock" :images="galleryImages" :index="galleryIndex" @close="galleryIndex=null"/>
</div>
</template>

<script>
import {SUBSCRIBE_ALL_PRODUCTS_WITH_STOCK} from "../../graphql/subscriptions/Products";
import {IDS} from "../../Constants/IDS";
import gallery from '../../mixins/gallery';
export default {
    name: "OrganizationStock",
    props: ['orgId'],
    mixins: [gallery],
    apollo: {
        $subscribe: {
            Products: {
                query: SUBSCRIBE_ALL_PRODUCTS_WITH_STOCK,
                skip(){return !this.orgId},
                variables() {return { orgId: this.orgId }},
                result({data}) {
                  let pStockObj = {};             //org stock productId.unitId (when we need to open box)
                  data.Products.forEach(p => {
                    p.packets = { quantity: 0, stock: 0, unitId: IDS.unitType.Piece };
                    p.boxes = { quantity: 0, stock: 0, unitId: IDS.unitType.Box };
                    pStockObj[p.id] = {[IDS.unitType.Box]:{quantity:0, reservedQuantity:0}, [IDS.unitType.Piece]:{quantity:0, reservedQuantity:0}};
                    p.OrganizationStock.forEach(s => {
                      pStockObj[p.id][s.unitId] = {quantity: s.quantity, reservedQuantity: s.reservedQuantity};
                    })
                  });
                    data.Products.forEach(p => {
                      let availablePiece = pStockObj[p.id][IDS.unitType.Piece].quantity - pStockObj[p.id][IDS.unitType.Piece].reservedQuantity;
                      let availableBox = pStockObj[p.id][IDS.unitType.Box].quantity - pStockObj[p.id][IDS.unitType.Box].reservedQuantity;
                      if(availablePiece<0 && availableBox>0) {
                        p.boxes.stock = availableBox - 1;
                        p.packets.stock = p['noOfPieceInBox'] + availablePiece;
                      }else{
                        p.boxes.stock = availableBox;
                        p.packets.stock = availablePiece;
                      }
                    });
                    this.products = data.Products;
                }
            },
        }
    },
    data(){
        return{
            products: [],
            packageColor: JSON.parse(localStorage.packageColor),
        }
    }
}
</script>

<style scoped>
.product-card{
    max-height: calc(100vh - 188px);
    overflow-y: auto;
    overflow-x: hidden;
}
.product-card .row{
    margin: unset;
}
.img-div{
    cursor: pointer;
}
</style>
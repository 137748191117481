import gql from "graphql-tag";
import {IDS} from "../../Constants/IDS";

export const SUBSCRIBE_DISTRIBUTOR_SALES_ORDERS = gql`
subscription subscribeDistributorSalesOrders($orgId: uuid!){
  Orders(where:{_and:[{ from: {_eq: $orgId}} ]}, order_by:{created_at:desc}){
    id
    orderNum    
    OrderItems_aggregate(distinct_on: productId) { aggregate { count } }
    created_at
    updated_at
    roundOfValue
    finalPrice    
    status
    TripOrderStatus {id name}
    ToRouteShops {id Route{id name}}                
    Organization{ id name Users(where:{userTypeId:{_eq:"${IDS.userTypes.Customer}"}}){id name} }    
  }
}`

export const SUBSCRIBE_DISTRIBUTOR_SALES_ORDER = gql`
subscription subscribeSalesOrder($id:uuid!){
    Orders(where:{id:{_eq:$id}}){
        id
        orderNum                       
        status
        TripOrderStatus {id name }            
        gstPrice 
        price
        finalPrice
        roundOfValue
        created_at
        updated_at
        salesBy
        Organization {id name orgCode mobile Users(where:{userTypeId:{_eq:"${IDS.userTypes.Customer}"}}){id name}}
        ToRouteShops {id Route{id name}}                                   
        Trip {id tripId}
        CreatedBy{ id name }
        OrderItems{ id quantity price perUnitPrice unitId productId                 
            Product{ id name image weight bunchKg noOfPieceInBox colorCode bunchPrice packageType containerType displayName }                    
        }
    }
}`
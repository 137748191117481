import gql from "graphql-tag";

export const SUBSCRIBE_ALL_PRODUCTS_WITH_STOCK = gql`
subscription ($orgId: uuid){
 Products(order_by: {sequence: asc_nulls_last}){
  id
  displayName
  bunchKg
  noOfPieceInBox
  image
  colorCode
  weight
  packageType
  containerType
  OrganizationStock(where: {organizationId: {_eq: $orgId}}) { unitId quantity reservedQuantity }        
 }
}`

export const SUBSCRIBE_ALL_PRODUCTS_WITH_PRICES = gql`
subscription {
 Products(order_by: {sequence: asc_nulls_last}){
  id
  name
  displayName
  bunchKg
  noOfPieceInBox
  image
  colorCode
  weight
  packageType
  isEnable
  scheme
  containerType
  sequence
  ProductPrices{ id price unitId priceListId }                                                          
}
}`

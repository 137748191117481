<template>
  <div id="CreateUpdateRoute">
    <v-dialog max-width="600" persistent v-model="saveRouteDialog">
      <v-card class="pa-5">
        <v-card-title class="mb-4">{{ route ? 'Update' : 'Create' }} Route</v-card-title>
        <v-card-text>
          <v-form ref="saveRouteForm">
            <v-row>
              <v-col class="py-1" cols="12">
                <v-text-field :rules="[rules.nameRequired]" dense label="Name*" outlined ref="name"
                              v-model="routeData.name"></v-text-field>
              </v-col>
              <v-col class="py-1" cols="12">
                <v-autocomplete class="text-body-2" :rules="[v => !!v || 'RTO Code Required']" :items="rtoCodes"
                                dense item-text="name" item-value="id" label="RTO Code Prefix*" @change="onChangeRtoCode"
                                return-object outlined v-model="routeData.rtoCodeId" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn @click="cancel" class="mr-2 white elevation-0">CANCEL</v-btn>
          <v-btn depressed @click="validate" class="mr-2 primary elevation-0">SAVE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { CREATE_UPDATE_ROUTE } from '@/graphql/mutations/Routes'
import {GET_DISTRIBUTOR_RTO_CODES} from "../../graphql/queries/Organizations";

export default {
  name: 'CreateUpdateRoute',
  props: {
    route: { type: [Object], default: null },
    saveRouteDialog: { type: [Boolean], default: false },
  },
  apollo: {
    rtoCodes: {query: GET_DISTRIBUTOR_RTO_CODES},
  },
  data () {
    return {
      routeData: { id: '', name: '', rtoCodeId:null, city:null, state:null },
      rules: { nameRequired: value => !!value || 'Name Required.' },
      rtoCodes: [],
    }
  },
  mounted () {
    if (this.route)
      this.routeData = JSON.parse(JSON.stringify(this.route))
    else
      this.routeData['id'] = this.generateUUID()
  },
  methods: {
    onChangeRtoCode(val){
      if(val) {
        this.routeData.rtoCodeId = val.id;
        this.routeData.city = val.city;
        this.routeData.state = val.state;
      }
    },
    validate () { if (this.$refs.saveRouteForm.validate()) this.saveRoute() },
    async saveRoute () {
      let routeObj = { id: this.routeData.id, name: this.routeData.name, rtoCodeId: this.routeData.rtoCodeId, city: this.routeData.city, state: this.routeData.state }
      let route_on_conflict_rule = { constraint: 'Routes_pkey', update_columns: ['name', 'rtoCodeId', 'city', 'state'] }
      let response = await this.$apollo.mutate({
        mutation: CREATE_UPDATE_ROUTE,
        variables: { routes: routeObj, route_on_conflict_rule: route_on_conflict_rule },
      })
      if (response.data)
        this.$emit('saveRoute')
    },
    cancel () { this.$emit('cancel') },
    generateUUID () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    },
  },
}
</script>

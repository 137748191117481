<template>
  <div id="MigrateDistributor">
    <v-dialog max-width="600" persistent v-model="migrateDistributorDialog">
      <v-card class="pa-5">
        <v-card-title class="mb-4">InActivate Distributor</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col class="py-1" cols="12">
                <v-autocomplete :items="distributors" :rules="[rules.distributorRequired]"
                                dense item-text="name"
                                item-value="id" label="Assign Other Distributor*" outlined ref="user"
                                v-model="selectedDistributor">
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title> {{ data.item.name }} ({{ checkVal(data.item.userCode) }})
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.mobile }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn @click="cancel" class="mr-2 white elevation-0">CANCEL</v-btn>
          <v-btn depressed @click="validate" class="mr-2 primary elevation-0">SAVE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmationDialog :confirmationMessage="confirmMsg" @close="confirmationDialog=false"
                        @confirm="migrateDistributor" v-if="confirmationDialog"></ConfirmationDialog>
  </div>
</template>

<script>
import { GET_FILTERED_DISTRIBUTOR } from '@/graphql/queries/Organizations'
import ConfirmationDialog from '@/components/Common/ConfirmationDialog'
import Constants from '@/Constants/Constants'
import NetworkCommunicator from '../../plugins/NetworkResourceHandler'
import { IDS } from '@/Constants/IDS'

export default {
  name: 'MigrateDistributor',
  components: { ConfirmationDialog },
  props: {
    distributor: { type: [Object], default: null },
    migrateDistributorDialog: { type: [Boolean], default: false },
  },
  data () {
    return {
      selectedDistributor: null, distributors: [], confirmMsg: '', confirmationDialog: false,
      rules: { distributorRequired: value => !!value || 'Distributor Required.' },
    }
  },
  apollo: {
    Distributors: {
      query: GET_FILTERED_DISTRIBUTOR,
      variables () {return { orgId: this.distributor.id, orgType: [IDS.organizationTypes.Distributor] }},
      skip () { return !this.distributor },
      result ({ data }) {
        this.distributors = data.Distributors
      },
    },
  },
  methods: {
    validate () {
      if (this.$refs.form.validate()) {
        this.confirmMsg = 'Confirm!, these will assign all routes and customer to new Distributor'
        this.confirmationDialog = true
      }
    },
    async migrateDistributor () {
      NetworkCommunicator('POST', `${Constants.api_url}migrateDistributor`,
          { oldDistributor: this.distributor.id, newDistributor: this.selectedDistributor }).then(res => {
        console.log(res)
        this.$emit('migrateDistributor')
      }).catch(err => {console.log(err)})
    },
    cancel () { this.$emit('cancel') },
  },
}
</script>

<template>
  <div id="AddExistingCustomer">
    <v-dialog fullscreen persistent v-model="addExistingCustomerDialog">
      <v-card elevation="0" class="pa-5 mt-8">
        <v-card-title>
          <div class="d-flex align-center">
            <div>
              <h4 class="text-h6">Select Existing Customers</h4>
              <p class="text-body-2 grey--text text--darken-1 mb-0">Drag & Drop shops to select shops for this route
                and for correct sequencing.</p>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('cancel')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height: calc(100vh - 210px)">
          <v-row class="mt-3" style="overflow-x: auto">
            <v-col class="pt-0 px-0"
                   style="height: 88vh; background: #F5F5F5; border-radius: 10px; overflow-x: hidden; position: relative">
              <div style="background: #EAEAEA; position: sticky; top: 0px; z-index: 9999"
                   class="d-flex align-center px-3 mb-3">
                <v-text-field class="my-3 mr-10" clearable dense flat hide-details label="Search Customers" outlined
                              style="max-width: 300px; background: white" v-model="searchOrganization"></v-text-field>
                <v-spacer></v-spacer>
                <h4 class="text-h5 black--text">{{ organizations.length }}</h4>
              </div>
              <draggable :list="filteredOrganizations" group="shops" class="px-3" style="min-height: 78vh; ">
                <v-list-item class="shop-list white mb-2 " style="width: 100%; cursor: grab" :key="index"
                             v-for="(item,index) in filteredOrganizations">
                  <div style="width: 100%" class="d-flex py-3">
                    <div style="width: 100%">
                      <h3 class="font-weight-medium text-body-2">{{ item.name }}</h3>
                      <div class="d-flex justify-space-between" style="width: 100%">
                        <p class="grey--text mb-0 text--darken-1 d-block text-caption">{{ item.mobile }}</p>
                        <p class="grey--text mb-0 text--darken-1 d-block"
                           style="font-size: 13px">{{ item.Type ? item.Type.type : '-' }}</p>
                      </div>
                    </div>
                  </div>
                </v-list-item>
              </draggable>
            </v-col>
            <v-col class="pt-0 px-0 ml-5"
                   style="height: 88vh; background: #F5F5F5; border-radius: 10px; overflow-x: hidden; position: relative">
              <div style="background: #EAEAEA; height: 64px; position: sticky; top: 0px; z-index: 9999"
                   class="d-flex align-center px-3 mb-3">
                <h4 class="text-subtitle-1 black--text">Selected Customers</h4>
                <v-spacer></v-spacer>
                <h4 class="text-h5 black--text">{{ selectedCustomers.length }}</h4>
              </div>
              <draggable @change="$emit('saveRouteDetails',$event)" :list="selectedCustomers" class="px-3"
                         group="shops" style="min-height: 78vh;">
                <v-list-item class="shop-list white mb-2 " style="width: 100%; cursor: grab" :key="index"
                             v-for="(item,index) in selectedCustomers">
                  <div style="width: 100%" class="d-flex py-3">
                    <div class="mr-3"><h3 class="font-weight-medium">{{ index + 1 }}.</h3></div>
                    <div style="width: 100%">
                      <h3 class="font-weight-medium text-body-2">{{ item.name }}</h3>
                      <div class="d-flex justify-space-between align-center" style="width: 100%">
                        <p class="grey--text text--darken-1 mb-0 text-caption" style="font-size: 13px">
                          {{ item.mobile }}</p>
                        <p class="grey--text text--darken-1 mb-0" style="font-size: 13px">
                          {{ item.Type ? item.Type.type : '-' }}</p>
                      </div>
                    </div>
                  </div>
                </v-list-item>
              </draggable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'AddExistingCustomer',
  components: { draggable },
  props: {
    addExistingCustomerDialog: { type: [Boolean], default: false },
    selectedCustomers: { type: [Array], default: () => {return []} },
    organizations: { type: [Array], default: () => {return []} },
  },
  data () { return { searchOrganization: '', filteredOrganizations: [] } },
  mounted () { this.filteredOrganizations = JSON.parse(JSON.stringify(this.organizations)) },
  watch: {
    searchOrganization (val) {
      if (val)
        this.filteredOrganizations = this.organizations.filter(organization => {
          return (organization.name.toLowerCase().indexOf(val.toLowerCase()) !== -1 ||
              organization.mobile.toLowerCase().indexOf(val.toLowerCase()) !== -1)
        })
      else
        this.filteredOrganizations = this.organizations
    },
  },
}
</script>

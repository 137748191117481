<template>
  <div id="AssignRoutes">
    <v-dialog width="720" scrollable persistent v-model="assignRouteDialog">
      <v-card>
        <v-card-title>
          <div class="d-flex justify-space-between" style="width: 100%">
            <div>Assign New Routes</div>
            <v-btn icon @click="$emit('cancel')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-spacer></v-spacer>
          <v-text-field class="mt-3" :loading="loading" clearable dense flat hide-details label="Search Routes"
                        outlined style="background: white" v-model="searchRoute"></v-text-field>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 60vh; overflow-y: auto;">
          <v-row>
            <v-col cols="12" class="px-0">
              <v-list>
                <v-list-item-group multiple v-model="selectedRoutes">
                  <v-list-item v-for="(route,index) in filteredRoutes" :key="index" :value="route.id"
                               active-class="text--primary text--accent-4">
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title class="text-body-1">{{ route.name }} ({{route.routeCode}})</v-list-item-title>
                        <v-list-item-subtitle class="text-caption">{{ route.RouteShops.length }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-checkbox :input-value="active" color="primary accent-4"></v-checkbox>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions >
          <v-spacer></v-spacer>
          <v-btn @click="$emit('cancel')" class="mr-3" text>CANCEL</v-btn>
          <v-btn :disabled="!selectedRoutes.length" depressed @click="assignRoutes" class="primary" text>ASSIGN ROUTES</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SUBSCRIBE_ORGANIZATION_UNASSIGNED_ROUTES } from '../../graphql/subscriptions/Routes'
import { ASSIGN_ORGANIZATION_ROUTES } from '@/graphql/mutations/Routes'

export default {
  name: 'AssignRoutes',
  props: {
    distributor: { type: [Object], default: null },
    assignRouteDialog: { type: [Boolean], default: false },
  },
  data () { return { unassignedRoutes: [], selectedRoutes: [], searchRoute: '', filteredRoutes: [], loading: true } },
  apollo: {
    $subscribe: {
      Routes: {
        query: SUBSCRIBE_ORGANIZATION_UNASSIGNED_ROUTES, fetchPolicy: 'network-only',
        result ({ data, loading }) {
          if (!loading) {
            this.unassignedRoutes = JSON.parse(JSON.stringify(data.Routes))
            this.filteredRoutes = JSON.parse(JSON.stringify(data.Routes))
            this.loading = false
          }
        },
      },
    },
  },
  watch: {
    searchRoute (val) {
      if (val)
        this.filteredRoutes = this.unassignedRoutes.filter(route => {
          return (route.name.toLowerCase().indexOf(val.toLowerCase()) !== -1)
        })
      else
        this.filteredRoutes = this.unassignedRoutes
    },
  },
  methods: {
    async assignRoutes () {
        let res = await this.$apollo.mutate({
          mutation: ASSIGN_ORGANIZATION_ROUTES,
          variables: { routeIds: this.selectedRoutes, orgId: this.distributor.id },
        })
        if (res.data) {
          this.$emit('assignRoute')
          this.$emit('showToast', { enable: true, color: 'green', message: 'Routes assigned successfully' })
        }
    },
  },
}
</script>

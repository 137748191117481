<template>
  <div id="CreateUpdateDistributor">
    <v-dialog persistent scrollable max-width="1400px" v-model="saveDistributorDialog">
      <v-card>
        <v-card-title>{{ distributor ? 'Update' : 'Create' }} Distributor</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 70vh;" class="py-3">
          <v-form ref="distributorForm">
            <v-row>
              <v-col cols="7">
                <v-row class="px-3">
                  <v-col cols="12" class="px-0 ">
                    <h6 class="text-subtitle-1 black--text">Company Details</h6>
                  </v-col>

                  <v-col cols="12 pa-0 mb-5">
                    <h5 class="text-body-2">Do we have FSSAI?</h5>
                    <v-row>
                      <v-col class="py-0" cols="12">
                        <v-radio-group row v-model="hasFSSAI">
                          <v-radio label="Yes" :value="true"></v-radio>
                          <v-radio label="No" :value="false"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col v-if="hasFSSAI" class="py-0" cols="12" :class="hasFSSAI ? 'd-block d-flex' : 'd-none'">
                        <v-text-field class="text-body-2" dense clearable label="FSSAI Number" outlined
                                      ref="fssaiCode" v-model="distributorData.fssaiCode"></v-text-field>
                        <v-btn depressed @click="getDataFromFSSAI" class="ml-3" color="primary">SEARCH</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="pa-0 mb-2">
                    <v-text-field class="text-body-2" dense :rules="[rules.companyNameRequired]" clearable
                                  label="Company Name*" outlined ref="name"
                                  v-model="distributorData.name"></v-text-field>
                  </v-col>
                  <v-col cols="4" class="py-0 pl-0 mb-2">
                    <v-select class="text-body-2" :rules="[rules.companyTypeRequired]" label="Company Type*"
                              :items="companyTypes" dense outlined v-model="distributorData.companyType"></v-select>
                  </v-col>
                  <v-col cols="4" class="py-0 px-0 mb-2">
                    <v-select class="text-body-2" :rules="[rules.orderTypeRequired]" label="Distributor Order Type*"
                              outlined dense :items="constants.DistributorOrderTypes"
                              item-value="value" item-text="label" v-model="distributorData.orderSystem"></v-select>
                  </v-col>
                  <v-col cols="4" class="py-0 pr-0 mb-2">
                    <v-autocomplete class="text-body-2" :rules="[rules.rtoCodeRequired]" :items="rtoCodes"
                                    dense item-value="id" item-text="name" ref="user" label="RTO Code Prefix*"
                                    outlined v-model="distributorData.rtoCode"/>
                     <!-- <template v-slot:selection="data">
                        <span>{{ data.item.code }} - {{ data.item.name }}</span>
                      </template>
                      <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-content>
                            <v-list-item-title> {{ data.item.code }} - {{ data.item.name }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete> -->
                  </v-col>
                  <v-col cols="6" class="py-0 pl-0 pr-0">
                    <v-text-field dense v-mask="'##NNNNNNNNNNNZX'" clearable label="GSTIN Number" class="text-body-2"
                                  outlined ref="gstin" v-model="distributorData.gstInNumber"></v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0 pr-0">
                    <v-autocomplete :items="users" :rules="[rules.assignToRequired]" class="text-body-2" dense
                                    item-text="name" outlined item-value="id" label="Assign To*" ref="user"
                                    v-model="distributorData.assignedSalesExecutive" multiple small-chips deletable-chips>
                      <template v-slot:item="{item}">
                        <template v-if="typeof item !== 'object'">
                          <v-list-item-content v-text="item"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-content>
                            <v-list-item-title> {{ item.name }} ({{ checkVal(item.userCode) }})
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ item.mobile }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="5">
                <v-row class="px-3">
                  <v-col cols="12" class="px-0 ">
                    <h6 class="text-subtitle-1 black--text">Address Details</h6>
                  </v-col>
                  <v-col cols="12" class="pa-0 mb-2">
                    <v-text-field class="text-body-2" dense clearable label="Address Line 1" outlined ref="address1"
                                  v-model="distributorData.Address.address1"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="pa-0 mb-2">
                    <v-text-field class="text-body-2" dense clearable label="Address Line 2" outlined ref="address2"
                                  v-model="distributorData.Address.address2"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0 mb-2 px-0">
                    <v-text-field class="text-body-2" dense clearable label="Area" outlined ref="area"
                                  v-model="distributorData.Address.area"></v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0 mb-2 pl-0">
                    <v-text-field class="text-body-2" dense clearable label="Pincode" outlined ref="pincode"
                                  v-model="distributorData.Address.pincode"></v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0 mb-2 pl-0 pr-0">
                    <v-text-field class="text-body-2" dense clearable label="City" outlined ref="city"
                                  v-model="distributorData.Address.city"></v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0 mb-2 pl-0">
                    <v-text-field class="text-body-2" dense clearable label="State" outlined ref="state"
                                  v-model="distributorData.Address.state"></v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0 mb-2 pr-0 pl-0">
                    <v-text-field class="text-body-2" dense clearable label="Country" outlined ref="country"
                                  v-model="distributorData.Address.country"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-5"></v-divider>
            <v-row class="px-3 pt-0">
              <v-col cols="12" class="px-0 mt-5 d-flex justify-space-between align-center">
                <h6 class="text-subtitle-1 black--text">Owner Details</h6>
                <v-btn v-if="distributorData.companyType!=='Proprietary'" elevation="0" small
                       @click="addOwner(false)">
                  <v-icon size="14" class="mr-2">mdi-plus</v-icon>
                  ADD OWNER
                </v-btn>
              </v-col>
              <v-row class="mt-1 mb-4" v-for="(owner,index) in distributorData.owners" :key="index">
                <v-col cols="12" class="px-1 py-0 mb-2 d-flex">
                  <v-text-field class="text-body-2 mx-2" dense :rules="[rules.nameRequired]" label="Enter Owner Name*"
                                outlined ref="name" v-model="owner.name"></v-text-field>
                  <v-text-field class="text-body-2 mx-2" :rules="[rules.mobileRequired]" dense label="Mobile*"
                                v-mask="'##########'" outlined ref="mobile" v-model="owner.mobile"></v-text-field>
                  <v-text-field class="text-body-2 mx-2" :rules="[rules.emailValid]" dense label="Email"
                                outlined ref="email" v-model="owner.email"></v-text-field>
                  <v-menu :close-on-content-click="false" :nudge-right="40" offset-y
                          transition="scale-transition" v-model="owner.birthDateMenu">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field class="text-body-2 mx-2" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
                                    @click:clear="resetBirthdateAnniversary('owners',index,'birthDate')"
                                    dense label="BirthDate" outlined clearable v-on="on"
                                    :value="birthDateAnniversaryFormatter(owner.birthDate)"></v-text-field>
                    </template>
                    <v-date-picker class="text-body-2" @input="owner.birthDateMenu = false"
                                   v-model="owner.birthDate"></v-date-picker>
                  </v-menu>
                  <v-menu :close-on-content-click="false" :nudge-right="40" offset-y
                          transition="scale-transition" v-model="owner.anniversaryMenu">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field class="text-body-2 mx-2" prepend-inner-icon="mdi-calendar" readonly clearable
                                    @click:clear="resetBirthdateAnniversary('owners',index,'anniversary')"
                                    dense label="Anniversary" outlined v-bind="attrs" v-on="on"
                                    :value="birthDateAnniversaryFormatter(owner.anniversary)"></v-text-field>
                    </template>
                    <v-date-picker class="text-body-2" @input="owner.anniversaryMenu = false"
                                   v-model="owner.anniversary"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="pt-0 mb-3 d-flex align-center">
                  <v-btn elevation="0" color="primary" @click="makeKeyOwner(index)" icon>
                    <v-icon>{{ owner.isKeyOwner ? 'mdi-checkbox-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
                  </v-btn>
                  <span>Is Default Owner?</span>
                  <v-btn class="ml-1" elevation="0" color="primary" @click="setOwnerAsContactPerson(owner,index)" icon>
                    <v-icon>{{ owner.isKeyPerson ? 'mdi-checkbox-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
                  </v-btn>
                  <span>Is Key Contact Person?</span>
                  <v-btn v-if="index!==0" class="ml-3" @click="removeOwner(owner,index)" small icon color="red">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-row>
            <v-divider class="mt-5"></v-divider>
            <v-row class="px-3 pt-0">
              <v-col cols="12" class="px-0 mt-5">
                <h6 class="text-subtitle-1 black--text">Contact Details</h6>
              </v-col>
              <v-row class="px-2 mt-1" v-for="(contactPerson,index) in distributorData.contactPersons" :key="index">
                <v-col cols="12" class="px-0 py-0 mb-2 d-flex">
                  <v-text-field class="mx-2" :readonly="contactPerson.isKeyOwner" dense :rules="[rules.nameRequired]"
                                outlined label="Enter Contact Person Name*" ref="name"
                                v-model="contactPerson.name"></v-text-field>
                  <v-text-field class="mx-2" :readonly="contactPerson.isKeyOwner" :rules="[rules.mobileRequired]" dense
                                outlined label="Mobile*" v-mask="'##########'" ref="mobile"
                                v-model="contactPerson.mobile"></v-text-field>
                  <v-text-field class="mx-2" :readonly="contactPerson.isKeyOwner" :rules="[rules.emailValid]" dense
                                label="Email" outlined ref="email" v-model="contactPerson.email"></v-text-field>
                  <v-menu :disabled="contactPerson.isKeyOwner" :close-on-content-click="false" :nudge-right="40"
                          min-width="290px" offset-y transition="scale-transition"
                          v-model="contactPerson.birthDateMenu">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field class="mx-2" label="BirthDate" outlined prepend-inner-icon="mdi-calendar" dense
                                    @click:clear="resetBirthdateAnniversary('contactPersons',index,'birthDate')"
                                    :value="birthDateAnniversaryFormatter(contactPerson.birthDate)" clearable
                                    readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker @input="contactPerson.birthDateMenu = false"
                                   v-model="contactPerson.birthDate"></v-date-picker>
                  </v-menu>
                  <v-menu :disabled="contactPerson.isKeyOwner" :close-on-content-click="false" :nudge-right="40"
                          min-width="290px" offset-y transition="scale-transition"
                          v-model="contactPerson.anniversaryMenu">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field class="mx-2" outlined prepend-inner-icon="mdi-calendar" clearable dense
                                    @click:clear="resetBirthdateAnniversary('contactPersons',index,'anniversary')"
                                    :value="birthDateAnniversaryFormatter(contactPerson.anniversary)"
                                    label="Anniversary" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker @input="contactPerson.anniversaryMenu = false"
                                   v-model="contactPerson.anniversary"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="2" class="px-2 py-0 mb-3 d-flex align-center">
                  <v-btn @click="resetContactPerson(contactPerson)" color="error" depressed x-small>RESET</v-btn>
                </v-col>
              </v-row>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel" class="mr-3 white elevation-0">CANCEL</v-btn>
          <v-btn :loading="loading" depressed @click="save" elevation="0" color="primary">SAVE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Constants from '@/Constants/Constants'
import { IDS } from '@/Constants/IDS'
import moment from 'moment'
import {
  CREATE_UPDATE_ORGANIZATION_USER_ADDRESS_EXECUTIVE
} from '../../graphql/mutations/Organizations'
import { GET_USERS_BY_USERTYPE } from '../../graphql/queries/Users'
import NetworkCommunicator from '@/plugins/NetworkResourceHandler'
import { DELETE_USER } from '@/graphql/mutations/Users'
import {GET_DISTRIBUTOR_RTO_CODES} from "../../graphql/queries/Organizations";

export default {
  name: 'CreateUpdateDistributor',
  props: {
    distributor: { type: Object, default: null },
    saveDistributorDialog: { type: [Boolean], default: false },
  },
  data () {
    return {
      constants: Constants, users: [], hasFSSAI: false, loading: false,
      companyTypes: ['Proprietary', 'Partner', 'Company'],
      distributorData: {
        orderSystem: 1, fssaiCode: null, fssaiCodeExpiryDate: null, mobile: '', whatsappNumber: '', name: '',
        type: IDS.organizationTypes.Distributor, gstInNumber: '', zone: '',
        Address: { address1: '', address2: '', area: '', city: '', pincode: '', state: '', country: '' },
        owners: [], contactPersons: [],
        User: { id: null, name: '', email: null, birthDate: null, anniversary: null }, companyType: null,
        assignedSalesExecutive: null, rtoCode: null,
      },
      loggedInUser: JSON.parse(localStorage.user),
      rtoCodes: [],
      rules: {
        mobileRequired: value => !!value || 'Mobile Required.',
        nameRequired: value => !!value || 'Name Required.',
        companyNameRequired: value => !!value || 'Company Name Required.',
        latRequired: value => !!value || 'Latitude Required.',
        longRequired: value => !!value || 'Longitude Required.',
        orderTypeRequired: value => !!value || 'Order Type Required.',
        assignToRequired: value => !!(value&&value.length) || 'Assign To Required.',
        rtoCodeRequired: value => !!value || 'RTO Code Required.',
        companyTypeRequired: value => !!value || 'Company Type Required.',
        emailValid: v => !v ||
            /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                v) || 'E-mail must be valid',
      },
    }
  },
  apollo: {
    rtoCodes: { query: GET_DISTRIBUTOR_RTO_CODES },
    Users: {
      query: GET_USERS_BY_USERTYPE, fetchPolicy: 'network-only',
      variables () {
        return { userTypeId: [IDS.userTypes.PilotSalesman, IDS.userTypes.SalesOfficer] }
      },
      result ({ data, loading }) {
        if (!loading) {
          this.users = data.Users
        }
      },
    },
  },
  mounted () {
    if (this.distributor) this.prepareDataFromObject(this.distributor)
    else {
      this.addOwner(true)
      this.addContactPerson()
    }
    this.$forceUpdate()
  },
  computed: {
    birthDateFormat: {
      get () {
        return this.distributorData.User.birthDate
            ? moment(this.distributorData.User.birthDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : null
      },
      set () {
        this.distributorData.User.birthDate = null
      },
    },
    anniversaryFormat: {
      get () {
        return this.distributorData.User.anniversary
            ? moment(this.distributorData.User.anniversary, 'YYYY-MM-DD').format('DD-MM-YYYY') : null
      },
      set () {
        this.distributorData.User.anniversary = null
      },
    },
  },
  methods: {
    addOwner (isKeyOwner = false) {
      this.distributorData.owners.push({
        id: null, name: '', email: null, mobile: null, birthDate: null, anniversary: null, isKeyOwner: isKeyOwner,
        birthDateMenu: false, anniversaryMenu: false, isKeyPerson: false,
      })
      this.$forceUpdate()
    },
    addContactPerson () {
      this.distributorData.contactPersons.push({
        id: null, name: '', email: null, mobile: null, birthDate: null, anniversary: null, isKeyOwner: false,
        birthDateMenu: false, anniversaryMenu: false, isKeyPerson: false,
      })
      this.$forceUpdate()
    },
    makeKeyOwner (ownerIndex) {
      this.distributorData.owners.forEach((owner, index) => { owner.isKeyOwner = index === ownerIndex })
      this.$forceUpdate()
    },
    async removeOwner (owner, ownerIndex) {
      if (owner.id) {
        if (owner.isKeyOwner)
          this.$emit('showToast', { enable: true, color: 'red', message: 'Can\'t remove key Owner' })
        else {
          let res = await this.$apollo.mutate({
            mutation: DELETE_USER, variables: { id: owner.id },
          })
          if (res.data) {
            this.distributorData.owners.splice(ownerIndex, 1)
            this.$emit('showToast', { enable: true, color: 'red', message: 'Owner removed successfully' })
          }
        }
      } else {
        this.distributorData.owners.splice(ownerIndex, 1)
      }
      this.$forceUpdate()
    },
    setOwnerAsContactPerson (val, ownerIndex) {
      this.distributorData.owners.forEach((owner, index) => { owner.isKeyPerson = index === ownerIndex })
      this.distributorData.contactPersons[0].name = val.name
      this.distributorData.contactPersons[0].email = val.email
      this.distributorData.contactPersons[0].mobile = val.mobile
      this.distributorData.contactPersons[0].birthDate = val.birthDate
      this.distributorData.contactPersons[0].anniversary = val.anniversary
      this.distributorData.contactPersons[0].isKeyPerson = val.isKeyPerson
      this.distributorData.contactPersons[0].isKeyOwner = true
      this.$forceUpdate()
    },
    resetContactPerson (val) {
      this.distributorData.owners.forEach(owner => { owner.isKeyPerson = false })
      if (val.id)
        this.distributorData.contactPersons[0].id = val.id
      this.distributorData.contactPersons[0].name = ''
      this.distributorData.contactPersons[0].email = null
      this.distributorData.contactPersons[0].mobile = null
      this.distributorData.contactPersons[0].birthDate = null
      this.distributorData.contactPersons[0].anniversary = null
      this.distributorData.contactPersons[0].isKeyPerson = val.isKeyPerson
      this.distributorData.contactPersons[0].isKeyOwner = false
      this.$forceUpdate()
    },
    prepareDataFromObject (org) {
      if (typeof org === 'object') {
        this.distributorData['id'] = org.id
        this.distributorData.orderSystem = org.orderSystem
        this.distributorData.type = org.Type.id
        this.distributorData.companyType = org.companyType
        this.distributorData.mobile = org.mobile
        this.distributorData.whatsappNumber = org.whatsappNumber
        this.distributorData.gstInNumber = org.gstInNumber
        this.distributorData.name = org.name
        this.distributorData.zone = org.zone
        this.distributorData.fssaiCode = org.fssaiCode
        this.distributorData.fssaiCodeExpiryDate = org.fssaiCodeExpiryDate
        this.distributorData.assignedSalesExecutive = org.SalesExecutives.map(s => s.salesExecutiveId);
        this.distributorData.rtoCode = org.rtoCode
        if (org.Address && org.Address.id)
          this.distributorData.Address = org.Address
        if (org.Users.length) {
          org.Users.forEach(user => {
            if (user.userTypeId === IDS.userTypes.Owner) {
              this.distributorData.owners.push({
                id: user.id, name: user.name, email: user.email, mobile: user.mobile,
                birthDate: user.birthDate, anniversary: user.anniversary, isKeyOwner: user.isKeyOwner,
                birthDateMenu: false, anniversaryMenu: false, isKeyPerson: user.isKeyPerson,
              })
            } else if (user.userTypeId === IDS.userTypes.ContactPerson) {
              this.distributorData.contactPersons.push({
                id: user.id, name: user.name, email: user.email, mobile: user.mobile,
                birthDate: user.birthDate, anniversary: user.anniversary, isKeyOwner: user.isKeyOwner,
                birthDateMenu: false, anniversaryMenu: false, isKeyPerson: user.isKeyPerson,
              })
            }
          })
          if (!this.distributorData.owners.length) this.addOwner(true)
          if (!this.distributorData.contactPersons.length) this.addContactPerson()
          //set first owner as default key owner if no default set
          let keyOwner = this.distributorData.owners.filter(owner => owner.isKeyOwner)
          if (!keyOwner.length)
            this.distributorData.owners[0].isKeyOwner = true
        } else {
          this.addOwner(true)
          this.addContactPerson()
        }
      }
      this.$forceUpdate()
    },
    async saveDistributor () {
      let org, address, users
      org = {
        name: this.distributorData.name, orderSystem: this.distributorData.orderSystem,
        type: this.distributorData.type, companyType: this.distributorData.companyType,
        zone: this.distributorData.zone, gstInNumber: this.distributorData.gstInNumber,
        fssaiCode: this.distributorData.fssaiCode, status: 'ACTIVE',
        fssaiCodeExpiryDate: this.distributorData.fssaiCodeExpiryDate,
        createdBy: this.loggedInUser.id, updatedBy: this.loggedInUser.id,
        parentOrgId: null, rtoCode: this.distributorData.rtoCode
      }
      if (org.type === IDS.organizationTypes.Dealer) org['priceListId'] = IDS.userPriceList.DealerSurat
      else if (org.type === IDS.organizationTypes.Retailer) org['priceListId'] = IDS.userPriceList.RetailerSurat
      else if (org.type === IDS.organizationTypes.Distributor) org['priceListId'] = IDS.userPriceList.DistributorSurat

      address = {
        city: this.distributorData.Address.city, state: this.distributorData.Address.state,
        area: this.distributorData.Address.area, pincode: this.distributorData.Address.pincode,
        country: this.distributorData.Address.country, address1: this.distributorData.Address.address1,
        address2: this.distributorData.Address.address2,
      }
      let orgId = this.generateUUID()
      if (this.distributor) {
        org['id'] = this.distributor.id
      } else {
        org['id'] = orgId
      }
      users = []
      this.distributorData.owners.forEach(owner => {
        users.push({
          id: owner.id ? owner.id : this.generateUUID(), organizationId: org['id'],
          name: owner.name, mobile: owner.mobile, email: owner.email, userTypeId: IDS.userTypes.Owner,
          birthDate: owner.birthDate, anniversary: owner.anniversary,
          isKeyOwner: owner.isKeyOwner, isKeyPerson: owner.isKeyPerson,
          createdBy: this.loggedInUser.id, updatedBy: this.loggedInUser.id,
        })
      })
      this.distributorData.contactPersons.forEach(contactPerson => {
        users.push({
          id: contactPerson.id ? contactPerson.id : this.generateUUID(), organizationId: org['id'],
          name: contactPerson.name, mobile: contactPerson.mobile, email: contactPerson.email,
          userTypeId: IDS.userTypes.ContactPerson,
          isKeyOwner: contactPerson.isKeyOwner, isKeyPerson: contactPerson.isKeyPerson,
          birthDate: contactPerson.birthDate, anniversary: contactPerson.anniversary,
          createdBy: this.loggedInUser.id, updatedBy: this.loggedInUser.id,
        })
      })
      if (this.distributor && this.distributor.Address) {
        address['id'] = this.distributorData.Address.id
        address['organizationId'] = this.distributor.id
      } else if (this.distributor) {
        address['organizationId'] = this.distributor.id
      } else {
        address['organizationId'] = orgId
      }
      //organization mobile from default owner
      let keyOwner = this.distributorData.owners.filter(owner => {if (owner.isKeyOwner) return owner})[0]
      org['whatsappNumber'] = keyOwner.mobile
      org['mobile'] = keyOwner.mobile

      let org_on_conflict_rule = {
        constraint: 'Organizations_pkey',
        update_columns: [
          'name', 'orderSystem', 'type', 'companyType', 'zone', 'gstInNumber', 'priceListId',
          'mobile', 'whatsappNumber', 'fssaiCode', 'fssaiCodeExpiryDate', 'address', 'status', 'rtoCode', 'updatedBy'],
      }
      let users_on_conflict_rule = {
        constraint: 'Users_pkey',
        update_columns: [
          'name', 'email', 'mobile', 'userTypeId', 'birthDate', 'anniversary',
          'isKeyOwner', 'isKeyPerson'],
      }
      let address_on_conflict_rule = {
        constraint: 'Addresses_pkey',
        update_columns: [
          'city', 'state', 'area', 'pincode', 'country', 'address1', 'address2', 'latitude', 'longitude'],
      }
      let variables = {
        orgs: org, org_on_conflict_rule: org_on_conflict_rule, orgId:org.id,
        users: users, users_on_conflict_rule: users_on_conflict_rule,
        address: address, address_on_conflict_rule: address_on_conflict_rule,
        orgSalesExecutives: this.distributorData.assignedSalesExecutive.map(s => ({orgId:org.id, salesExecutiveId:s}))
      }
      let res = await this.$apollo.mutate({
        mutation: CREATE_UPDATE_ORGANIZATION_USER_ADDRESS_EXECUTIVE, variables: variables,
      })
      if (res.data) {
        this.$emit('saveDistributor', res.data.Organization.returning[0])
      }
    },
    getDataFromFSSAI () {
      NetworkCommunicator('GET', `https://api.rajwafers.anant.io/api/fssaiDetails/${this.distributor.fssaiCode}`).
          then(res => {
            if (res.flag) {
              this.oldOrganizations = []
              let orgData = res.data.FboDetailsLicenseWise
              this.distributorData.name = orgData.Company
              this.distributorData.fssaiCodeExpiryDate = orgData.ExpireDate
              this.distributorData.User.name = orgData.Contact_Person
              this.distributorData.User.email = orgData.EmailId
              this.distributorData.Address.state = orgData.State
              this.distributorData.Address.city = orgData.District_Name
              this.distributorData.mobile = orgData.FBO_MobileNo
              this.distributorData.whatsappNumber = orgData.FBO_MobileNo
              this.$forceUpdate()
            }
          })
    },
    birthDateAnniversaryFormatter (inputVal) {
      return inputVal ? moment(inputVal, 'YYYY-MM-DD').format('DD-MM-YYYY') : null
    },
    resetBirthdateAnniversary (whose, index, whichDate) { this.distributorData[whose][index][whichDate] = null },
    save () {
      this.loading = true
      if (this.$refs.distributorForm.validate()) this.saveDistributor()
      else this.loading = false
    },
    cancel () { this.$emit('cancel') },
    generateUUID () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    },
  },
}
</script>
<style scoped>


</style>

<template>
  <div id="Distributors">
    <v-container fluid>
      <v-card height="50" class="elevation-0 px-1 mb-5">
        <v-card-title>
          <v-row>
            <div @click="filterDistributors('all')" class="count-widget-all px-3 py-2"
                 :class="selectedFilter==='all'?'count-widget-all-active':''">
              <p class="text-body-2 mb-0">All Distributors</p>
              <h4 class="text-subtitle-1 font-weight-bold">{{ ActiveOrganizations + InActiveOrganizations }}</h4>
            </div>
            <div @click="filterDistributors('active')" class="count-widget-assign px-3 py-2 ml-2"
                 :class="selectedFilter==='active'?'count-widget-assign-active':''">
              <p class="text-body-2 mb-0">Active Distributors</p>
              <h4 class="text-subtitle-1 font-weight-bold">{{ ActiveOrganizations }}</h4>
            </div>
            <div @click="filterDistributors('inactive')" class="count-widget px-3 py-2 ml-2"
                 :class="selectedFilter==='inactive'?'count-widget-active':''">
              <p class="text-body-2 mb-0">Inactive Distributors</p>
              <h4 class="text-subtitle-1 font-weight-bold">{{ InActiveOrganizations }}</h4>
            </div>
            <v-spacer></v-spacer>
            <v-col class="pa-0" cols="3">
              <v-text-field dense clearable label="Search Distributors" outlined v-model="search"></v-text-field>
            </v-col>
            <v-col class="pa-0 ml-2" cols="auto">
              <v-btn v-if="permissions.permissionMeta.create" depressed @click="startCreateDistributor" class="primary">
                ADD DISTRIBUTOR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <v-row>
        <v-col cols="12" class="px-2 mt-3">
          <v-data-table fixed-header :footer-props="{itemsPerPageOptions:[25,50,100]}" :items-per-page="25"
                        class="elevation-0" @click:row="viewDistributor" height="70vh" :search="search"
                        :loading="loading" :custom-filter="searchDistributors" :headers="distributorsHeaders"
                        :items="distributors">
            <template v-slot:no-data>No Distributors Available</template>
            <template v-slot:no-results>No Distributors Available</template>
            <template v-slot:item.orgCode="{ item }">
              {{ checkVal(item.orgCode) }}
            </template>
            <template v-slot:item.name="{ item }">
              {{ checkVal(item.name) }}
            </template>
            <template v-slot:item.userName="{ item }">
              {{ item.Users.length ? checkVal(item.Users[0].name) : '-' }}
            </template>
            <template v-slot:item.mobile="{ item }">
              {{ checkVal(item.mobile) }}
            </template>
            <template v-slot:item.zone="{ item }">
              {{ checkVal(item.zone) }}
            </template>
            <template v-slot:item.routes="{ item }">
              {{ item.Routes.length }}
            </template>
            <template v-slot:item.orderSystem="{ item }">
              {{ checkVal(orderTypeFilter(item.orderSystem)) }}

            </template>
            <template v-slot:item.assignedSalesExecutive="{ item }">
              <template v-for="(s, sIndex) in item.SalesExecutives">{{s.SalesExecutive.name}}
                <template v-if="(sIndex+1)!==item.SalesExecutives.length">, </template>
              </template>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex align-center">
                <v-btn v-if="permissions.permissionMeta.update" @click.stop="onClickAction(actionArr[0],item)" icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="permissions.permissionMeta.update" @click.stop="onClickAction(actionArr[2],item)" icon>
                  <v-icon>mdi-account-circle-outline</v-icon>
                </v-btn>
                <v-btn icon @click.stop="onClickAction(actionArr[1],item)" v-if="permissions.permissionMeta.delete">
                  <v-icon :color="item.status==='ACTIVE'?'blue':'red'" size="32">
                    {{ item.status === 'ACTIVE' ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off-outline' }}
                  </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <CreateUpdateDistributor :distributor="distributor" @cancel="saveDistributorDialog=false"
                             v-if="saveDistributorDialog" @saveDistributor="saveDistributor" @showToast="showToaster"
                             :saveDistributorDialog="saveDistributorDialog"></CreateUpdateDistributor>
    <DistributorDetail :permissions="permissions" :distributorId="distributor.id" @showToast="showToaster"
                       :distributorDetailDialog="viewDistributorDialog" @cancel="viewDistributorDialog=false"
                       v-if="viewDistributorDialog"></DistributorDetail>
    <AssignSalesExecutive @saveSalesExecutive="salesExecutiveDialog=false" :distributor="distributor"
                          :salesExecutiveDialog="salesExecutiveDialog" @cancel="salesExecutiveDialog=false"
                          v-if="salesExecutiveDialog"></AssignSalesExecutive>
    <MigrateDistributor @migrateDistributor="updateDistributorStatus" :distributor="distributor"
                        :migrateDistributorDialog="migrateDistributorDialog"
                        @cancel="migrateDistributorDialog=false" v-if="migrateDistributorDialog"></MigrateDistributor>
    <ConfirmationDialog :confirmationMessage="confirmMsg" @close="confirmationDialog=false;confirmMsg = 'Confirm! '"
                        @confirm="confirmAction" v-if="confirmationDialog"></ConfirmationDialog>
    <Snackbar :toaster="toaster"></Snackbar>
  </div>
</template>

<script>
import {
  SUBSCRIBE_ACTIVE_ORGANIZATION_COUNT,
  SUBSCRIBE_DISTRIBUTORS,
  SUBSCRIBE_INACTIVE_ORGANIZATION_COUNT,
} from '../graphql/subscriptions/Organizations'
import { IDS } from '@/Constants/IDS'
import CreateUpdateDistributor from '../components/Distributors/CreateUpdateDistributor'
import Snackbar from '@/components/Common/Snackbar'
import ConfirmationDialog from '@/components/Common/ConfirmationDialog'
import DistributorDetail from '@/components/Distributors/DistributorDetail'
import AssignSalesExecutive from '@/components/Distributors/AssignSalesExecutive'
import { SOFT_DELETE_ORGANIZATION } from '@/graphql/mutations/Organizations'
import MigrateDistributor from '@/components/Distributors/MigrateDistributor'

export default {
  name: 'Distributors',
  components: {
    MigrateDistributor, AssignSalesExecutive, DistributorDetail, ConfirmationDialog, Snackbar, CreateUpdateDistributor,
  },
  data () {
    return {
      distributors: [], distributor: null, search: null, toaster: {}, selectedAction: {},
      ActiveOrganizations: 0, InActiveOrganizations: 0, selectedFilter: 'all',
      condition: { type: { _in: [IDS.organizationTypes.Distributor] } },
      saveDistributorDialog: false, viewDistributorDialog: false, loading: true, permissions: {},
      salesExecutiveDialog: false, migrateDistributorDialog: false, confirmationDialog: false, confirmMsg: 'Confirm! ',
      distributorsHeaders: [
        { text: 'Distributor Code', value: 'orgCode' }, { text: 'Company Name', value: 'name'},
        { text: 'Name', value: 'userName', sortable: false }, { text: 'Mobile', value: 'mobile' },
        { text: 'Routes', value: 'routes', sortable: false }, { text: 'Order System', value: 'orderSystem' },
        { text: 'Assign To', value: 'assignedSalesExecutive', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      actionArr: [
        { id: '1', title: 'Edit' }, { id: '2', title: 'Delete' }, { id: '3', title: 'Assign Sales Executive' }],
    }
  },
  apollo: {
    $subscribe: {
      Distributors: {
        query: SUBSCRIBE_DISTRIBUTORS, fetchPolicy: 'network-only',
        variables () {
          return {
            condition: this.condition,
            userType: [IDS.userTypes.Owner, IDS.userTypes.ContactPerson],
          }
        },
        result ({ data }) {
          this.distributors = data.Distributors
          this.loading = false
        },
      },
      ActiveOrganizations: {
        query: SUBSCRIBE_ACTIVE_ORGANIZATION_COUNT, fetchPolicy: 'network-only',
        variables () {return { orgType: [IDS.organizationTypes.Distributor] }},
        result ({ data, loading }) {
          if (!loading) {
            this.ActiveOrganizations = data.ActiveOrganizations.aggregate.count
          }
        },
      }, InActiveOrganizations: {
        query: SUBSCRIBE_INACTIVE_ORGANIZATION_COUNT, fetchPolicy: 'network-only',
        variables () {return { orgType: [IDS.organizationTypes.Distributor] }},
        result ({ data, loading }) {
          if (!loading) {
            this.InActiveOrganizations = data.InActiveOrganizations.aggregate.count
          }
        },
      },
    },
  },
  beforeMount () {
    this.permissions = this.$store.getters.permissionByScreen('Distributors')
  },
  methods: {
    onClickAction (selectedAction, selectedUser) {
      this.distributor = selectedUser
      this.selectedAction = this.actionArr.filter(action => selectedAction.id === action.id)[0]
      if (this.selectedAction.id === '1') {
        this.confirmAction()
      } else if (this.selectedAction.id === '2') {
        this.distributor.status === 'ACTIVE' ? this.migrateDistributorDialog = true : this.confirmationDialog = true
        this.confirmMsg = 'Confirm! this will update distributor status, are you sure?'
      } else if (this.selectedAction.id === '3') {
        this.assignSalesExecutive()
      }
    },
    confirmAction () {
      this.confirmationDialog = false
      this.confirmMsg = 'Confirm! '
      if (this.selectedAction.id === '1')
        this.editDistributor()
      else if (this.selectedAction.id === '2')
        this.updateDistributorStatus()
    },
    startCreateDistributor () {
      this.distributor = null
      this.saveDistributorDialog = true
    },
    saveDistributor () {
      this.showToaster({ enable: true, color: 'green', message: `Distributor saved successfully` })
      this.saveDistributorDialog = false
    },
    editDistributor () { this.saveDistributorDialog = true },
    async updateDistributorStatus () {
      let response = await this.$apollo.mutate({
        mutation: SOFT_DELETE_ORGANIZATION,
        variables: {
          id: this.distributor.id, status: this.distributor.status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE',
        },
      })
      if (response.data) {
        this.showToaster({ enable: true, color: 'green', message: 'Distributor successfully updated' })
        this.migrateDistributorDialog = false
      }
    },
    viewDistributor (selectedUser) {
      this.distributor = selectedUser
      this.viewDistributorDialog = true
    },
    assignSalesExecutive () {this.salesExecutiveDialog = true},
    filterDistributors (filterType) {
      this.selectedFilter = filterType
      if (this.selectedFilter === 'active')
        this.condition = { status: { _eq: 'ACTIVE' }, type: { _in: [IDS.organizationTypes.Distributor] } }
      else if (this.selectedFilter === 'inactive')
        this.condition = { status: { _eq: 'INACTIVE' }, type: { _in: [IDS.organizationTypes.Distributor] } }
      else this.condition = { type: { _in: [IDS.organizationTypes.Distributor] } }
    },
    searchDistributors (value, search, item) {
      if (value != null && search != null) {
        let val = search?.toString().toLocaleLowerCase()
        return item.name.toString().toLocaleLowerCase().indexOf(val) !== -1 ||
            item.mobile?.toString().toLocaleLowerCase().indexOf(val) !== -1 ||
            item.orgCode?.toString().toLocaleLowerCase().indexOf(val) !== -1 ||
            item.Users[0]?.name.toString().toLocaleLowerCase().indexOf(val) !== -1
      }
    },
    showToaster (data) { this.toaster = data },
  },
}
</script>
<style scoped>
/deep/ tr:hover {
  cursor: pointer !important;
}
</style>

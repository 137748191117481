<template>
  <div id="RouteShopList">
    <v-dialog width="680" scrollable persistent v-model="routeShopDialog">
      <v-card v-if="routeDetails">
        <v-card-title class="align-start">
          <div style="width: 100%" class="d-flex">
            <div>
              <div>Route Customers Details</div>
              <p class="text-caption mb-0">Route {{ routeDetails.name }} ({{routeDetails.routeCode}}) | Created On {{
                  dateTimeFilter(routeDetails.created_at)
                }}</p>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click="$emit('cancel')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div style="width: 100%" class="d-flex justify-space-between">
            <v-text-field :loading="loading" class="mt-3" clearable dense flat hide-details label="Search Customers"
                          outlined
                          style="background: white" v-model="searchShop"></v-text-field>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" class="primary ml-5 mt-3" depressed>ADD CUSTOMER</v-btn>
              </template>
              <v-list>
                <v-list-item @click="saveOrganizationDialog=true">
                  <v-list-item-title class="text-body-2">NEW CUSTOMER</v-list-item-title>
                </v-list-item>
                <v-list-item @click="addExistingCustomerDialog=true">
                  <v-list-item-title class="text-body-2">EXISTING CUSTOMER</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text style="height: 60vh; overflow-y: auto;" class="py-3" v-if="routeDetails.RouteShops.length">
          <v-row>
            <v-col class="py-1 px-0" cols="12">
              <v-list v-for="(shop,index) in filteredRouteShops" :key="index">
                <v-list-item>
                  <v-list-item-content class="d-flex">
                    <div class="d-flex justify-space-between">
                      <div>
                        <v-list-item-title class="text-body-1">{{ shop.Organization.name }}</v-list-item-title>
                        <v-list-item-subtitle class="text-caption">{{ shop.Organization.mobile }}</v-list-item-subtitle>
                      </div>
                      <div>
                        <!--                        <v-btn @click="updateRouteShopStatus(shop)" :color="shop.status === 'ACTIVE' ? 'red' : 'green'"-->
                        <!--                               class="white&#45;&#45;text" x-small depressed>-->
                        <!--                          {{ shop.status === 'ACTIVE' ? 'INACTIVATE' : 'ACTIVATE' }}-->
                        <!--                        </v-btn>-->
                        <v-btn @click="removeRouteShop(shop)" x-small class="ml-3 red--text" depressed fab
                               color="transparent">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="index < filteredRouteShops.length - 1"></v-divider>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text style="height: 60vh;" v-else>
          <v-row align="center" justify="center">
            <v-col cols="12" class="text-center text-h5 mt-16">No Routes Customers Available</v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ConfirmationDialog :confirmationMessage="confirmMsg" @close="confirmationDialog=false"
                        @confirm="confirmAction" v-if="confirmationDialog"></ConfirmationDialog>
    <CreateUpdateOrganization :saveOrganizationDialog="saveOrganizationDialog" @cancel="saveOrganizationDialog=false"
                              @saveDistributor="appendShopInRoute" @showToast="$emit('showToast',$event)"
                              v-if="saveOrganizationDialog"></CreateUpdateOrganization>
    <AddExistingCustomer @cancel="addExistingCustomerDialog=false" :selectedCustomers="selectedShops"
                         :addExistingCustomerDialog="addExistingCustomerDialog" :organizations="organizations"
                         @saveRouteDetails="saveRouteDetails" v-if="addExistingCustomerDialog"></AddExistingCustomer>
  </div>
</template>

<script>
import { GET_ROUTE_BY_ID } from '../../graphql/subscriptions/Routes'
import { DELETE_ROUTE_SHOP, UPDATE_ROUTE_SHOPS_STATUS } from '@/graphql/mutations/RouteShops'
import ConfirmationDialog from '@/components/Common/ConfirmationDialog'
import CreateUpdateOrganization from '@/components/Organizations/CreateUpdateOrganization'
import AddExistingCustomer from '@/components/Routes/AddExistingCustomer'
import { deleteRecordById } from '@/graphql/mutations/Common'
import { CREATE_UPDATE_ROUTE_SHOPS } from '@/graphql/mutations/Routes'
import { GET_ROUTE_SHOPS, GET_ROUTE_SHOPS_BY_ID } from '@/graphql/queries/Routes'
import { GET_FILTERED_ORGANIZATIONS } from '@/graphql/queries/Organizations'
import { IDS } from '@/Constants/IDS'

export default {
  name: 'RouteShopList',
  components: { AddExistingCustomer, CreateUpdateOrganization, ConfirmationDialog },
  props: {
    route: { type: [String], default: null },
    routeShopDialog: { type: [Boolean], default: false },
  },
  data () {
    return {
      routeDetails: null, loading: true, searchShop: '', filteredRouteShops: [],
      selectedShops: [], selectedShopsIds: [], organizations: [],
      confirmMsg: '', confirmationDialog: false, selectedShop: null,
      addExistingCustomerDialog: false, saveOrganizationDialog: false,
    }
  },
  apollo: {
    $subscribe: {
      Route: {
        query: GET_ROUTE_BY_ID, fetchPolicy: 'network-only',
        skip () { return !this.route }, variables () { return { id: this.route } },
        result ({ data, loading }) {
          if (!loading) {
            this.routeDetails = JSON.parse(JSON.stringify(data.Routes[0]))
            this.filteredRouteShops = JSON.parse(JSON.stringify(this.routeDetails.RouteShops))
            this.getOrgAndRouteShops()
            this.loading = false
            this.$forceUpdate()
          }
        },
      },
    },
  },
  watch: {
    searchShop (val) {
      if (val)
        this.filteredRouteShops = this.routeDetails.RouteShops.filter(route => {
          return (route.Organization.name.toLowerCase().indexOf(val.toLowerCase()) !== -1 ||
              route.Organization.mobile.toLowerCase().indexOf(val.toLowerCase()) !== -1)
        })
      else
        this.filteredRouteShops = this.routeDetails.RouteShops
    },
  },
  methods: {
    async getOrgAndRouteShops () {
      let res = await this.$apollo.query({
        query: GET_ROUTE_SHOPS, fetchPolicy: 'network-only',
      })
      if (res.data) {
        this.selectedShopsIds = []
        res.data.RouteShops.forEach(shop => {
          this.selectedShopsIds.push(shop.organizationId)
        })
        let res1 = await this.$apollo.query({
          query: GET_ROUTE_SHOPS_BY_ID, fetchPolicy: 'network-only', variables: { routeId: this.route },
        })
        if (res1.data) {
          this.selectedShops = []
          res1.data.RouteShops.forEach(shop => {
            this.selectedShops.push({
              id: shop.organizationId, lat: shop.lat, uniqueId: shop.id, long: shop.long,
              mobile: shop.Organization.mobile, name: shop.Organization.name, zone: shop.Organization.zone,
              Type: shop.Organization.Type, status: shop.status,
            })
          })
          this.$forceUpdate()
          let res2 = await this.$apollo.query({
            query: GET_FILTERED_ORGANIZATIONS, fetchPolicy: 'network-only',
            variables: { orgIds: this.selectedShopsIds, orgType: [IDS.organizationTypes.Distributor] },
          })
          if (res2.data) {
            this.organizations = JSON.parse(JSON.stringify(res2.data.Organizations))
            this.$forceUpdate()
          }
        }
      }
    },
    async confirmAction () {
      let res = await this.$apollo.mutate({
        mutation: DELETE_ROUTE_SHOP, variables: { id: this.selectedShop.id },
      })
      if (res.data)
        this.confirmationDialog = false
    },
    async removeRouteShop (routeShop) {
      this.selectedShop = routeShop
      this.confirmMsg = 'Confirm!, this will remove shop from current route.'
      this.confirmationDialog = true
    },
    async updateRouteShopStatus (routeShop) {
      await this.$apollo.mutate({
        mutation: UPDATE_ROUTE_SHOPS_STATUS,
        variables: { id: routeShop.id, status: routeShop.status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE' },
      })
    },
    async appendShopInRoute (data) {
      this.selectedShops.push(data)
      await this.saveRouteShops()
    },
    async saveRouteDetails (actionData) {
      let actionType = Object.keys(actionData)[0]
      let actionId = actionData[actionType].element.uniqueId
      // eslint-disable-next-line no-prototype-builtins
      if (actionData.hasOwnProperty('removed')) {
        this.deleteShopId = actionId
        this.selectedShops.splice(this.selectedShops.findIndex(item => item.uniqueId === actionId), 1)
        this.$forceUpdate()
        await deleteRecordById(this, this.deleteShopId, 'RouteShops').then(async () => {
          await this.saveRouteShops()
        })
      } else {
        await this.saveRouteShops()
      }
    },
    async saveRouteShops () {
      async function removeDuplicates (myArr, prop) {
        return myArr.filter((obj, pos, arr) => {
          return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
        })
      }

      let routeShops = []
      await removeDuplicates(this.selectedShops, 'id').then(shops => {
        this.selectedShops = JSON.parse(JSON.stringify(shops))
        this.selectedShops.forEach((shop, index) => {
          routeShops.push({
            organizationId: shop.id, routeId: this.route,
            lat: shop.lat, long: shop.long, sequence: index + 1,
          })
          if (shop.uniqueId)
            routeShops[index]['id'] = shop.uniqueId
        })
      }).then(async () => {
        let routeShops_on_conflict_rule = {
          constraint: 'RouteShops_pkey', update_columns: ['organizationId', 'routeId', 'lat', 'long', 'sequence'],
        }
        let response = await this.$apollo.mutate({
          mutation: CREATE_UPDATE_ROUTE_SHOPS,
          variables: { routeShops: routeShops, routeShops_on_conflict_rule: routeShops_on_conflict_rule },
        })
        if (response.data) {
          this.$emit('saveRouteDetails')
          this.saveOrganizationDialog = false
          await this.getOrgAndRouteShops()
        }
      })
    },
  },
}
</script>

<template>
  <div id="AssignSalesExecutive">
    <v-dialog max-width="600" persistent v-model="salesExecutiveDialog">
      <v-card class="pa-5">
        <v-card-title class="mb-4">Assign Sales Executive</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col class="py-1" cols="12">
                <v-autocomplete :items="users" :rules="[rules.salesExecutiveRequired]" dense item-text="name"
                                item-value="id" label="Sales Executive*" outlined ref="user"
                                v-model="assignedSalesExecutive" multiple small-chips deletable-chips>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title> {{ data.item.name }} ({{ checkVal(data.item.userCode) }})
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.mobile }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn @click="cancel" class="mr-2 white elevation-0">CANCEL</v-btn>
          <v-btn depressed @click="validate" class="mr-2 primary elevation-0">SAVE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { IDS } from '@/Constants/IDS'
import { GET_USERS_BY_USERTYPE } from '@/graphql/queries/Users'
import { UPDATE_ORGANIZATION_SALES_EXECUTIVE } from '../../graphql/mutations/Organizations'

export default {
  name: 'AssignSalesExecutive',
  props: {
    distributor: { type: [Object], default: null },
    salesExecutiveDialog: { type: [Boolean], default: false },
  },
  data () {
    return {
      assignedSalesExecutive: null, users: [],
      rules: { salesExecutiveRequired: value => !!(value&&value.length) || 'Sales Executive Required.' },
    }
  },
  apollo: {
    Users: {
      query: GET_USERS_BY_USERTYPE, fetchPolicy: 'network-only',
      variables () {return { userTypeId: [IDS.userTypes.PilotSalesman, IDS.userTypes.SalesOfficer] }},
      result ({ data, loading }) { if (!loading) { this.users = data.Users } },
    },
  },
  mounted () {
    if (this.distributor)
      this.assignedSalesExecutive = this.distributor.SalesExecutives.map(s => s.salesExecutiveId);
  },
  methods: {
    validate () {
      if (this.$refs.form.validate())
        this.saveSalesExecutive()
    },
    async saveSalesExecutive () {
      let response = await this.$apollo.mutate({
        mutation: UPDATE_ORGANIZATION_SALES_EXECUTIVE,
        variables: {
          id: this.distributor.id,
          orgSalesExecutives: this.assignedSalesExecutive.map(s => ({orgId:this.distributor.id, salesExecutiveId:s}))
        },
      })
      if (response.data)
        this.$emit('saveSalesExecutive')
    },
    cancel () { this.$emit('cancel') },
  },
}
</script>

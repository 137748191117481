import gql from 'graphql-tag'

export const CREATE_UPDATE_ROUTE = gql`
    mutation ($routes:[Routes_insert_input!]!, $route_on_conflict_rule: Routes_on_conflict!) {
        insert_Routes(objects: $routes, on_conflict: $route_on_conflict_rule){
            returning{
                id
            }
        }
    }`

export const CREATE_UPDATE_ROUTE_SHOPS = gql`
    mutation ($routeShops:[RouteShops_insert_input!]!, $routeShops_on_conflict_rule: RouteShops_on_conflict!) {
        insert_RouteShops(objects: $routeShops, on_conflict: $routeShops_on_conflict_rule){
            returning{
                id
            }
        }
    }`

export const ASSIGN_ORGANIZATION_ROUTES = gql`
    mutation ($routeIds:[uuid!],$orgId:uuid!){
        update_Routes(where: {id: {_in: $routeIds}}, _set: {organizationId: $orgId}){
            returning{
                id
            }
        }
    }`

export const UNASSIGN_ORGANIZATION_ROUTES = gql`
    mutation ($routeIds:[uuid!]){
        update_Routes(where: {id: {_in: $routeIds}}, _set: {organizationId: null}){
            returning{
                id
            }
        }
    }`
export const SOFT_DELETE_ROUTE = gql`
    mutation ($id: uuid!,$status:String!){
        update_Routes(where: {id: {_eq: $id}}, _set: {status: $status}){
            returning{
                id
            }
        }
    }`

<template>
  <div id="DistributorDetail">
    <v-dialog fullscreen hide-overlay persistent v-model="distributorDetailDialog">
      <v-card v-if="distributor" style="background: #e9ecee">
        <v-toolbar style="box-shadow: 0 15px 40px -20px rgba(40,44,63,.15);">
          <v-container class="d-flex align-center">
            <v-toolbar-title>Distributor Details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="$emit('cancel')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-container>
        </v-toolbar>
        <v-card-text class="mt-5">
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-card style="box-shadow: 0 15px 40px -20px rgba(40,44,63,.15);" class="pa-8 rounded-0">
                  <div class="mb-1">
                    <div class="d-flex align-start justify-space-between">
                      <h2 class="font-weight-medium ">{{ checkVal(distributor.name) }}</h2>
                      <v-btn v-if="permissions.permissionMeta.update" fab icon x-small
                             @click="saveDistributorDialog=true">
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </div>
                    <span class="grey--text">{{ checkVal(distributor.orgCode) }}</span>
                  </div>
                  <div class="text-body-2 mb-6 grey--text text--darken-2" v-if="distributor.Address">
                    <div v-if="distributor.Address.address1">{{ distributor.Address.address1 }},</div>
                    <div v-if="distributor.Address.address2">{{ distributor.Address.address2 }},</div>
                    <div v-if="distributor.Address.area">{{ distributor.Address.area }},</div>
                    <div>
                      <span v-if="distributor.Address.city">{{ distributor.Address.city }} - </span>
                      <span v-if="distributor.Address.pincode">{{ distributor.Address.pincode }},</span>
                      <span v-if="distributor.Address.state">{{ distributor.Address.state }},</span>
                      <span v-if="distributor.Address.country">{{ distributor.Address.country }}</span>
                    </div>
                    <div v-if="distributor.lat && distributor.long">Lat - {{ distributor.lat }} , Long -
                      {{ distributor.long }}
                    </div>
                  </div>
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="grey--text text--darken-2">Mobile</span>
                    <span class="grey--text text--darken-2">{{ checkVal(distributor.mobile) }}</span>
                  </div>
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="grey--text text--darken-2">GSTIN</span>
                    <span class="grey--text text--darken-2">{{ checkVal(distributor.gstInNumber) }}</span>
                  </div>
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="grey--text text--darken-2">Company Type</span>
                    <span class="grey--text text--darken-2">{{ checkVal(distributor.companyType) }}</span>
                  </div>
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="grey--text text--darken-2">FSSAI Code</span>
                    <span class="grey--text text--darken-2">{{ checkVal(distributor.fssaiCode) }}</span>
                  </div>
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="grey--text text--darken-2">Order System</span>
                    <span class="grey--text text--darken-2">
                      {{ checkVal(orderTypeFilter(distributor.orderSystem)) }}</span>
                  </div>
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="grey--text text--darken-2">Assign To</span>
                    <span class="grey--text text--darken-2">
                      <div v-for="(s, sIndex) in distributor.SalesExecutives" :key="sIndex">{{s.SalesExecutive.name}}
                        <template v-if="(sIndex+1)!==distributor.SalesExecutives.length">, </template>
                      </div>
                    </span>
                  </div>
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="red--text text--darken-2">Total Due Amount</span>
                    <span class="red--text text--darken-2">
                      {{ distributor.totalDueAmount ? `₹ ${distributor.totalDueAmount}` : '-' }}
                    </span>
                  </div>
                  <hr class="my-4">
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="grey--text text--darken-2">Created By</span>
                    <span class="grey--text text--darken-2">{{
                        checkVal(distributor.CreatedBy && distributor.CreatedBy.name)
                      }}</span>
                  </div>
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="grey--text text--darken-2">Created On</span>
                    <span class="grey--text text--darken-2">{{ dateTimeFilter(distributor.created_at) }}</span>
                  </div>
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="grey--text text--darken-2">Updated By</span>
                    <span class="grey--text text--darken-2">
                      {{ checkVal(distributor.UpdatedBy && distributor.UpdatedBy.name) }}</span>
                  </div>
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="grey--text text--darken-2">Updated On</span>
                    <span class="grey--text text--darken-2">{{ dateTimeFilter(distributor.updated_at) }}</span>
                  </div>
                  <GmapMap
                      :center="(distributor.lat&&distributor.long)?{lat:Number(distributor.lat), lng:Number(distributor.long)}:{lat:0,lng:0}"
                      :zoom="17" style="width: 100%; height: 200px">
                    <GmapMarker
                        :position="(distributor.lat&&distributor.long)?{lat:Number(distributor.lat), lng:Number(distributor.long)}:{lat:0,lng:0}"/>
                  </GmapMap>
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-tabs v-model="selectedTab">
                  <v-tab>Routes ({{distributor&&distributor.Routes.length}})</v-tab>
                  <v-tab>Team ({{distributor&&distributor.Users.length}})</v-tab>
                  <v-tab>Purchase ({{distributor&&distributor.PurchaseOrders.length}})</v-tab>
                  <v-tab>Sales ({{ distributor&&distributor.SalesOrders_aggregate.aggregate.count }})</v-tab>
                  <v-tab>Stock ({{productCount}})</v-tab>
                </v-tabs>
                <v-tabs-items v-model="selectedTab">
                  <v-tab-item v-for="(tab,index) in tabs" :key="index">
                    <template v-if="tab.id===1">
                      <ManageRoutes @showToast="$emit('showToast',$event)" :distributor="distributor"></ManageRoutes>
                    </template>
                    <template v-if="tab.id===2">
                      <DistributorTeam :distributor="distributor"></DistributorTeam>
                    </template>
                    <template v-if="tab.id===3">
                      <PurchaseOrders :distributor="distributor"></PurchaseOrders>
                    </template>
                    <template v-if="tab.id===4">
                      <SalesOrders :orgId="distributorId"/>
                    </template>
                    <template v-if="tab.id===5">
                      <OrganizationStock :orgId="distributorId"/>
                    </template>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <CreateUpdateDistributor :distributor="distributor" @cancel="saveDistributorDialog=false"
                             @showToast="$emit('showToast',$event)" @saveDistributor="saveDistributor"
                             v-if="saveDistributorDialog"
                             :saveDistributorDialog="saveDistributorDialog"></CreateUpdateDistributor>
  </div>
</template>

<script>
import ManageRoutes from '../../components/Distributors/ManageRoutes'
import CreateUpdateDistributor from '@/components/Distributors/CreateUpdateDistributor'
import { SUBSCRIBE_DISTRIBUTOR_BY_ID } from '../../graphql/subscriptions/Organizations'
import { IDS } from '@/Constants/IDS'
import DistributorTeam from '@/components/Distributors/DistributorTeam'
import PurchaseOrders from '../../components/Distributors/PurchaseOrders'
import OrganizationStock from "./OrganizationStock";
import {GET_ALL_PRODUCTS_COUNT} from "../../graphql/queries/Products";
import SalesOrders from "./SalesOrders";

export default {
  name: 'DistributorDetail',
  components: {SalesOrders, OrganizationStock, PurchaseOrders, DistributorTeam, CreateUpdateDistributor, ManageRoutes },
  props: {
    distributorId: { type: String, default: null },
    distributorDetailDialog: { type: [Boolean], default: false },
    permissions: { type: Object },
  },
  apollo: {
    productCount:{
      query: GET_ALL_PRODUCTS_COUNT,
      result({data}) { this.productCount = data.productCount.aggregate.count}
    },
    $subscribe: {
      Organizations: {
        query: SUBSCRIBE_DISTRIBUTOR_BY_ID, fetchPolicy: 'network-only',
        skip () {return !this.distributorId},
        variables () {return { id: this.distributorId, userType: [IDS.userTypes.Owner, IDS.userTypes.ContactPerson] }},
        result ({ data, loading }) { if (!loading) { this.distributor = data.Organizations[0] } },
      },
    },
  },
  data () {
    return {
      saveDistributorDialog: false, distributor: null,
      tabs: [{ id: 1, label: 'Routes' }, { id: 2, label: 'Team' }, { id: 3, label: 'Purchase' },
             { id: 4, label: 'Sales' }, { id: 5, label: 'Stock' }],
      selectedTab: 0,
      productCount: 0
    }
  },
  methods: {
    saveDistributor () {
      this.$emit('showToast', { enable: true, color: 'green', message: `Distributor saved successfully` })
      this.saveDistributorDialog = false
    },
  },
}
</script>

<template>
  <div id="OrderDetail">
    <v-dialog persistent fullscreen scrollable v-model="dialog">
      <v-card class="pa-5">
        <v-card-title>
          <h6 class="text-h6">Sales Order Detail</h6><v-spacer/>
          <v-btn @click="closeDialog" icon><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="order">
          <v-row>
            <v-col class="px-8" style="border-right: 1px solid #E0E0E0;" cols="3">
              <div class="mb-10 mt-5">
                <h1 class="text-h4 black--text font-weight-bold">Order</h1>
                <h3 class="font-weight-bold"># {{ order.orderNum }}</h3>
              </div>
              <v-card outlined class="rounded-0">
                <div class="pa-5 green lighten-5">
                  <h6 class="text-caption green--text text--lighten-2">Total Amount</h6>
                  <h3 class="text-body-1 green--text font-weight-medium">{{ order.roundOfValue | currencyFormatter }}</h3>
                </div>
                <div class="pa-3">
                  <h6 class="text-caption grey--text">Order Status</h6>
                  <h3 class="text-body-1">{{ order.TripOrderStatus.name }}</h3>
                </div>
                <v-divider></v-divider>
                <div class="pa-5">
                  <h6 class="text-caption grey--text">Shop Name</h6>
                  <h3 class="text-body-1">{{ order.Organization.name }}</h3>
                </div>
                <v-divider></v-divider>
                <div class="pa-5">
                  <h6 class="text-caption grey--text">Customer Name</h6>
                  <h3 class="text-body-1">{{ order.Organization.Users.length?order.Organization.Users[0].name:'-' }}</h3>
                </div>
                <!--  <v-divider></v-divider>
                  <div class="pa-5">
                    <h6 class="text-caption grey--text">Customer Mobile</h6>
                    <h3 class="text-body-1">{{ order.Organization.mobile }}</h3>
                  </div> -->
                <v-divider></v-divider>
                <div class="pa-5">
                  <h6 class="text-caption grey--text">Route</h6>
                  <h3 class="text-body-1">{{ order.ToRouteShops.length?order.ToRouteShops[0].Route.name:'-' }}</h3>
                </div>
                <v-divider></v-divider>
                <div class="pa-5">
                  <h6 class="text-caption grey--text">Trip ID</h6>
                  <h3 class="text-body-1">{{ order.Trip?order.Trip.tripId:'-' }}</h3>
                </div>
                <!-- <v-divider></v-divider>
                 <div class="pa-5">
                   <h6 class="text-caption grey--text">Invoice Number</h6>
                   <h3 class="text-body-1">{{ checkVal(order.tallyInvoiceNum) }}</h3>
                 </div>
                 <v-divider></v-divider>
                 <div class="pa-5">
                   <h6 class="text-caption grey--text">Invoice Date</h6>
                   <h3 class="text-body-1">{{ dateFormatter(order.invoiceDate) }}</h3>
                 </div> -->
                <v-divider></v-divider>
                <div class="pa-5">
                  <h6 class="text-caption grey--text">Created On</h6>
                  <h3 class="text-body-1">{{ dateTimeFilter(order.created_at) }}</h3>
                </div>
                <v-divider></v-divider>
                <div class="pa-5">
                  <h6 class="text-caption grey--text">Updated On</h6>
                  <h3 class="text-body-1">{{ dateTimeFilter(order.updated_at) }}</h3>
                </div>
                <v-divider></v-divider>
                <div class="pa-5">
                  <h6 class="text-caption grey--text">Sales By</h6>
                  <h3 class="text-body-1">{{ order.salesBy?mapSalesBy[order.salesBy]:'-'}}</h3>
                </div>
                <v-divider></v-divider>
                <div class="pa-5">
                  <h6 class="text-caption grey--text">Created By</h6>
                  <h3 class="text-body-1">{{ order.CreatedBy.name }}</h3>
                </div>
              </v-card>
            </v-col>
            <v-col class="mt-5 px-4" cols="6" style="height: calc(100vh - 110px); overflow-y: auto">
              <div class="mb-10 ">
                <h1 class="text-h4 black--text font-weight-bold">Products</h1>
                <h6 class="text-caption font-weight-bold">{{ products.length }} ITEMS</h6>
              </div>
              <v-card elevation="0" class="py-0" v-for="(p, index) in products" :key="index" cols="12">
                <v-card-text class="py-0">
                  <v-row>
                    <v-col cols="2" class="text-center px-0 img-div" @click="openGallery([{image: p.image}])">
                      <v-img class="d-block mx-auto" contain height="100"
                             :src="p.image?imgWpx(p.image, 200):'../src/assets/logo.png'"></v-img>
                      <v-chip label text-color="white" x-small v-if="p.packageType" class="mt-3"
                              :style="`background-color: ${packageColor[p.packageType]}`">
                        {{ p.packageType.toUpperCase() }}
                      </v-chip>
                    </v-col>
                    <v-col cols="10">
                      <div class="d-flex justify-space-between align-start">
                        <h6 class="text-h6 font-weight-medium black--text">{{p.displayName}}</h6>
                        <span class="qty-text">{{ p.boxes }} {{p.containerType}}{{p.boxes>1?'es':''}}<br> {{p.pieces}} Piece{{p.pieces>1?'s':'' }}</span>
                      </div>
                      <p class="text-caption font-weight-medium mb-0">{{ p.noOfPieceInBox }} Pieces in {{p.containerType}}</p>
                      <h6 class="text-subtitle-1 mt-3 font-weight-bold">{{ p.price | currencyFormatter }}</h6>
                    </v-col>
                    <v-col cols="12" class="pt-6 pb-9">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="px-8" style="border-left: 1px solid #E0E0E0;" cols="3">
              <div class="mb-10 mt-5">
                <h1 class="text-h4 black--text font-weight-bold">Pricing</h1>
              </div>

              <div class="d-flex justify-space-between mb-2">
                <span class="text-body-1">Order Amount</span>
                <span class="text-body-1 font-weight-medium">{{ order.price | currencyFormatter }} </span>
              </div>
              <div class="d-flex justify-space-between mb-2">
                <span class="text-body-1">CGST(6%)</span>
                <span class="text-body-1 font-weight-medium">{{ (0.06*order.price) | currencyFormatter }}</span>
              </div>
              <div class="d-flex justify-space-between mb-2">
                <span class="text-body-1">SGST(6%)</span>
                <span class="text-body-1 font-weight-medium">{{ (0.06*order.price) | currencyFormatter}}</span>
              </div>
              <div class="d-flex justify-space-between ">
                <span class="text-body-1">Total GST(+12%)</span>
                <span class="text-body-1 font-weight-medium">{{ (0.12*order.price) | currencyFormatter}}</span>
              </div>
              <v-divider class="my-3"></v-divider>
              <div class="d-flex justify-space-between green--text">
                <span class="text-body-1  font-weight-medium">Total Amount</span>
                <span class="text-body-1  font-weight-medium">{{ order.roundOfValue | currencyFormatter }}</span>
              </div>
              <div class="d-flex justify-space-between green--text">
                <span class="text-body-1  font-weight-medium">Rounding Off</span>
                <span class="text-body-1  font-weight-medium">{{ order.finalPrice | currencyFormatter }}</span>
              </div>
              <div class="d-flex justify-space-between green--text">
                <span class="text-body-1  font-weight-medium">Payable Amount</span>
                <span class="text-body-1  font-weight-medium">{{ order.finalPrice | currencyFormatter }}</span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <gallery id="gallery-sales-order-detail" :images="galleryImages" :index="galleryIndex" @close="galleryIndex=null"/>
  </div>
</template>

<script>
import {SUBSCRIBE_DISTRIBUTOR_SALES_ORDER} from "../../graphql/subscriptions/SalesOrders";
import {IDS} from "../../Constants/IDS";
import gallery from '../../mixins/gallery';
export default {
  name: 'SalesOrderDetail',
  mixins: [gallery],
  props: {
    dialog: { type: [Boolean], default: false },
    orderId: { type: String, default: null },
  },
  data () {
    return {
      order: null,
      products: [],
      packageColor: JSON.parse(localStorage.packageColor),
      mapSalesBy: {[IDS.PreOrderSalesBy.PilotSalesman]: 'Pilot Salesman', [IDS.PreOrderSalesBy.Self]: 'Self'}
    }
  },
  apollo: {
    $subscribe: {
      Orders: {
        query: SUBSCRIBE_DISTRIBUTOR_SALES_ORDER, fetchPolicy: 'network-only',
        skip () {return !this.orderId}, variables () {return { id: this.orderId }},
        result ({ data, loading }) { if (!loading) { this.afterFetchOrder(data.Orders[0]) } },
      },
    },
  },
  methods:{
    afterFetchOrder(order){
      this.order = order;
      let pObj = {};                // productId.unitId
      order.OrderItems.forEach(i => {
        if(!pObj[i.productId])
          pObj[i.productId] = {[IDS.unitType.Box]:{quantity:0, price:0}, [IDS.unitType.Piece]:{quantity:0, price:0}, product:i.Product};
        pObj[i.productId][i.unitId] = {quantity: i.quantity, price: i.price};
      });
      this.products = Object.keys(pObj).map(k => ({
        ...pObj[k].product,
        boxes: pObj[k][IDS.unitType.Box].quantity, pieces: pObj[k][IDS.unitType.Piece].quantity,
        price: pObj[k][IDS.unitType.Box].price + pObj[k][IDS.unitType.Piece].price
      }));
    },
    closeDialog(){
      this.$emit('update:dialog', false);
    }
  }
}
</script>
<style scoped>
.img-div{
  cursor: pointer;
}
.qty-text{
  width: 24%;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
}
</style>
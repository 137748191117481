var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"PurchaseOrders"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.distributor.PurchaseOrders},on:{"click:row":_vm.viewOrder},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v("No Orders Available")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v("No Orders Available")]},proxy:true},{key:"item.orderNum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkVal(item.orderNum))+" ")]}},{key:"item.roundOfValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormatter(item.roundOfValue))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateTimeFilter(item.created_at))+" ")]}},{key:"item.tallyInvoiceNum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkVal(item.tallyInvoiceNum))+" ")]}},{key:"item.invoiceDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormatter(item.invoiceDate))+" ")]}}])})],1)],1),(_vm.orderDetailDialog)?_c('OrderDetail',{attrs:{"orderId":_vm.order.id,"orderDetailDialog":_vm.orderDetailDialog},on:{"cancel":function($event){_vm.orderDetailDialog=false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
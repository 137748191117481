import gql from 'graphql-tag'

/*export const GET_ALL_PRICES_AND_PRODUCTS = gql`
    query($priceListId:uuid!,$unitId:uuid!){
        Prices(where:{priceListId:{_eq:$priceListId},unitId:{_eq:$unitId}}){
            id
            price
            Product{
                id
                displayName
                bunchKg
                noOfPieceInBox
                image
                colorCode
                weight
                packageType
                containerType
                isEnable
            }
        }
    }` */

export const GET_ALL_PRODUCTS_WITH_PRICES = gql`
query allProductsWithPrice($priceListId:uuid!,$unitId:uuid!){
 Products(order_by: {sequence: asc_nulls_last}){
  id  
  displayName
  bunchKg
  noOfPieceInBox
  image
  colorCode
  weight
  packageType
  containerType
  isEnable  
  ProductPrices(where:{priceListId:{_eq:$priceListId},unitId:{_eq:$unitId}}){ id price }                                                          
}
}`

export const GET_ALL_PRODUCTS_COUNT = gql`
query{
 productCount: Products_aggregate{
  aggregate{count}        
 }
}`
export const GET_PRODUCT_SCHEME = gql`
query($id:uuid!){
 Products(where:{id:{_eq:$id}}){
  id
  scheme        
 }
}`

export const GET_PRODUCT_WITH_PRICES = gql`
query getProductWithPrices($id:uuid!){
 Products(where:{id:{_eq:$id}}){
  id
  name
  displayName
  bunchKg
  noOfPieceInBox
  image
  colorCode
  weight
  packageType  
  containerType  
  ProductPrices{ 
    id price unitId priceListId
    additionalPrice: additional_distribution_price_30_to_100
  }            
 }
}`
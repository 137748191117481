<template>
<div>

    <v-dialog v-model="dialog" persistent max-width="600" scrollable>
        <v-card>
            <v-card-title class="text-h5 pl-6 font-weight-regular">Export Routes
                <v-spacer/><v-btn icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title><v-divider/>
            <v-progress-linear :indeterminate="true" :active="loading"/>
            <v-card-text class="">
                <v-row>
                    <v-col cols="12"><v-checkbox class="ml-2" v-model="selectAll" label="Select All" hide-details/></v-col>
                    <v-col cols="12 ">
                        <v-list>
                            <v-list-item v-for="(route,index) in filteredRoutes" :key="index">
                                <v-list-item-content>
                                    <v-list-item-title class="text-body-1">{{ route.name }} ({{route.routeCode}})</v-list-item-title>
                                    <v-list-item-subtitle class="text-caption">Customer ({{ route.RouteShops.length }})</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-checkbox v-model="selectedRoutes" :value="route.id" color="primary accent-4"></v-checkbox>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pa-3">
                <v-spacer></v-spacer>
                <v-btn class="text-capitalize elevation-0 mr-2" @click="closeDialog">Cancel</v-btn>
                <v-btn class="primary px-3 text-capitalize" :loading="exportLoading" dark @click="exportRoutes" :disabled="!selectedRoutes.length">Export</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <Snackbar :toaster="toaster"/>
</div>
</template>

<script>
import {SUBSCRIBE_ORGANIZATION_ASSIGNED_ROUTES} from "../../graphql/subscriptions/Routes";
import axios from 'axios';
import Constants from "../../Constants/Constants";
import Snackbar from "../Common/Snackbar";
export default {
    name: "ExportRoutes",
    components: {Snackbar},
    props: ['dialog', 'distributor'],
    apollo: {
        $subscribe: {
            Routes: {
                query: SUBSCRIBE_ORGANIZATION_ASSIGNED_ROUTES, fetchPolicy: 'network-only',
                skip(){return !this.dialog},
                variables () {return { orgId: this.distributor.id }},
                result ({ data, loading }) {
                    if (!loading) {
                        this.loading = false;
                        this.assignedRoutes = JSON.parse(JSON.stringify(data.Routes));
                        this.filteredRoutes = JSON.parse(JSON.stringify(data.Routes));
                    }
                },
            },
        },
    },
    data () {
        return {
            assignedRoutes: [], filteredRoutes: [],
            exportLoading: false,
            loading: true,
            selectedRoutes: [],
            toaster: {enable: false}
        }
    },
    computed:{
        selectAll:{
            get() {return this.selectedRoutes.length === this.filteredRoutes.length},
            set(val) {
                if(val)this.selectedRoutes = this.filteredRoutes.map(r => r.id);
                else this.selectedRoutes = [];
            }
        }
    },
    methods:{
        exportRoutes(){
            this.exportLoading = true;
            let request = {
                orgId: this.distributor.id,
                routeId: this.selectedRoutes
            };
            axios({method: 'POST', url: `${Constants.api_url}organizationRouteDataExport`, data: request, responseType: 'blob'}).then(response => {
                let elem = window.document.createElement('a');
                elem.href = window.URL.createObjectURL(response.data);
                elem.download = 'Route_'+ this.distributor.name;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
                this.exportLoading = false;
                this.closeDialog();
           }).catch(error => {
                this.exportLoading = false;
                if(error.message === 'Network Error') {
                    this.toaster = {enable:true, color:'red', message: error.message};
                } else this.toaster = {enable:true, color:'red', message: `API Call Fail`};
            });
        },
        closeDialog(){
            this.selectedRoutes = [];
            this.$emit('update:dialog', false);
        }
    }
}
</script>

<style scoped>

</style>
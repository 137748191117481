import gql from 'graphql-tag'

export const UPDATE_ROUTE_SHOPS_STATUS = gql`
    mutation ($id: uuid!,$status:String!){
        update_RouteShops(where: {id: {_eq: $id}}, _set: {status: $status}){
            returning{
                id
            }
        }
    }`

export const DELETE_ROUTE_SHOP = gql`
    mutation ($id: uuid!){
        delete_RouteShops(where :{id: {_eq: $id}}){
            returning{
                id
            }
        }
    }`

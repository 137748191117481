var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"DistributorTeam"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.distributor.Users},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v("No Members Available")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v("No Members Available")]},proxy:true},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkVal(item.email))+" ")]}},{key:"item.birthDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormatter(item.birthDate))+" ")]}},{key:"item.anniversary",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormatter(item.anniversary))+" ")]}},{key:"item.roleType",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(item.isKeyOwner && item.Type.name==='Owner')?_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v(" Default Owner ")]):_c('v-chip',{staticClass:"text--primary",attrs:{"small":"","color":"secondary"}},[_vm._v(_vm._s(item.Type.name))])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
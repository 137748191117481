// Mixins
import Vue from 'vue';
import {generate_UPDATE_RECORD} from "../../graphql/mutations/Common";


/**
 * updateRecord
 * @mixin
 *
 * Used to update record by id
 */

export default Vue.extend({
    name: 'updateRecord',
    methods:{
        async updateRecord(id, obj, table){
            let response = await this.$apollo.mutate({
                mutation: generate_UPDATE_RECORD(table),
                variables: { id, obj }
            });
            return Promise.resolve(response.data);
        }
    }
});